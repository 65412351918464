import React, {Component, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {$cWarn, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";


const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 600,
	},
});


class SubTablaResultados extends Component {
	state = {};
	Usr = {};
	
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			folio: props.item.folio,
			item: props.item,
			listar_candidatos: props.item.candidatos || [],
		}
	}
	
	componentDidMount() {
		$cWarn("componentDidMount");
	}
	
	componentWillUnmount() {
		$cWarn("componentWillUnmount");
	}
	
	pasos = () => {
		let curculo = [
			{
				fecha_alta: "12/12/2019",
				serie: '1111111',
				folio: '2222222',
				folio_fiscal: '3333333',
				url_xml_factura: 'http://localhost:3000/facturacion',
				url_pdf_factura: 'http://localhost:3000/facturacion',
			},
			{
				fecha_alta: "12/12/2019",
				serie: '1111111',
				folio: '2222222',
				folio_fiscal: '3333333',
				url_xml_factura: 'http://localhost:3000/facturacion',
				url_pdf_factura: 'http://localhost:3000/facturacion',
			},
		
		];
		return curculo;
	};
	
	
	exportar = (url) => {
		try {
			if (!url) {
				throw Object({
					status: false, mensaje: 'URL Anexo no registrada.'
				})
			}
			
			window.open(url, '_blank');
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	render() {
		
		
		const {classes} = this.props;
		
		return (
			<Fragment>
				<div className={'bg-white card-1'}>
					
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={'px-14 text-center bg-white padding-5'}>
							Evaluaciones:
						</Typography>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Table className={classes.table + ' desing-integraciones'}>
							<TableHead>
								<TableRow>
									<TableCell component="th" align={'left'} style={{width: '15%'}}>Nombre</TableCell>
									<TableCell component="th" align={'left'}>Puesto</TableCell>
									<TableCell component="th" align={'left'}>Teléfonno</TableCell>
									<TableCell component="th" align={'left'}>Correo Electrónico</TableCell>
									<TableCell component="th" align={'left'}>Fecha/Hora Disponible</TableCell>
									<TableCell component="th" align={'left'}>Fecha/Hora Entrevista</TableCell>
									<TableCell component="th" align={'center'}>Confirmo</TableCell>
									<TableCell component="th" align={'center'}>Aplicado</TableCell>
									{/*<TableCell component="th" align={'left'}>Score</TableCell>*/}
									<TableCell component="th" align={'left'}>Nivel</TableCell>
									<TableCell component="th" align={'left'} style={{width: '15%'}}>Dictamen</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.state.listar_candidatos.map((item, key) => {
									return (
										<Fragment key={key}>
											<TableRow>
												<TableCell align={'left'}>{item.nombre_completo}</TableCell>
												<TableCell align={'left'}>{item.puesto}</TableCell>
												<TableCell align={'left'}>{item.telefono}</TableCell>
												<TableCell align={'left'}>{item.correo_electronico}</TableCell>
												<TableCell align={'left'}>{item.fecha_disponible+'/'+item.hora_disponible}</TableCell>
												<TableCell align={'left'}>{item.fecha_entrevista && item.hora_entrevista ? item.fecha_entrevista+'/'+item.hora_entrevista:'Sin registrar'}</TableCell>
												<TableCell align={'center'}>
													{item.confirmo === '' || item.confirmo === null ? 'Sin registrar' : Number(item.confirmo) === 1 ?
														<Fab size="small" color="primary" aria-label="add">Si</Fab> : <Fab size="small" color="secondary" aria-label="add">No</Fab>
													}
												</TableCell>
												<TableCell align={'center'}>
													{item.aplicada === '' || item.aplicada === null ? 'Sin registrar' : Number(item.aplicada) === 1 ?
														<Fab size="small" color="primary" aria-label="add">Si</Fab> : <Fab size="small" color="secondary" aria-label="add">No</Fab>
													}
												</TableCell>
												{/*<TableCell align={'left'}>{item.score ? item.score:'Sin registrar'}</TableCell>*/}
												<TableCell align={'left'}>{item.evaluacion_estatus ? item.evaluacion_estatus:'Sin registrar'}</TableCell>
												<TableCell align={'left'}>{item.mensaje ? item.mensaje:'Sin registrar'}</TableCell>
											</TableRow>
										</Fragment>
									);
								})
								}
							</TableBody>
						</Table>
					</Grid>
				
				</div>
			</Fragment>
		);
	}
}

SubTablaResultados.propTypes = {
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired
};

export default withStyles(styles)(SubTablaResultados);
