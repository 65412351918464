import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {CotizacionService} from "../../../../services/_Sis/CotizacionService/CotizacionService";
import SubTablaCotizaciones from "./SubTablaCotizaciones";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import {AccountBox, Description, GroupAdd, Help, LibraryAddCheck, LocalAtm, MailOutline} from "@material-ui/icons";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ModalSendCotizacion from "./ModalSendCotizacion";
import ModalNube from "./ModalNube";
import {FieldsJs} from "../../../../settings/General/General";
import ModalOptionsCotizacion from "./ModalOptionsCotizacion";
import ModalChatCotizacion from "./ModalChatCotizacion";
import icon_chat from "../../../../assets/img/icons/chat1.svg";
import Badge from '@material-ui/core/Badge';
import ModalComprobantePago from "./ModalComprobantePago";
import ModalCandidatos from "./ModalCandidatos";

class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar la cotización con id: ${item.id_cotizacion}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				CotizacionService.Eliminar(item.id_cotizacion).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	render() {
		
		const {lista} = this.props;
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
								...
							</TableCell>
							<TableCell component="th" align={'left'} className={'w-40-px padding-20-L padding-20-R'}>
								Folio
							</TableCell>
							{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<TableCell component="th" align={'left'}>Solicitud</TableCell>
							) : null}
							<TableCell component="th" align={'left'}>Empresa</TableCell>
							<TableCell component="th" align={'left'}>Contacto Empresa</TableCell>
							<TableCell component="th" align={'left'}>Teléfono</TableCell>
							<TableCell component="th" align={'left'}>Celular</TableCell>
							<TableCell component="th" align={'left'}>Correo Electrónico</TableCell>
							<TableCell component="th" align={'left'}>Estatus</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<Fragment key={key}>
								<TableRow>
									<TableCell align={'left'} className={'padding-20-L padding-20-R'}>
										<div className={'w-100'} align={'center'}>
											{item.partidas.length > 0 ? (
												<div style={!!item.expandir ? bg_menos : bg_mas}
												     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
												     onClick={() => this.props.SubTabla(item, key)}
												/>
											) : (
												<div>...</div>
											)}
										
										</div>
									</TableCell>
									<TableCell align={'left'} className={'padding-20-L padding-20-R'}>{item.folio}</TableCell>
									{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
										<TableCell align={'left'}>{item.id_usuario ? (
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cliente Registrado">
												<IconButton aria-label="Cliente Registrado">
													<AccountBox style={{color: '#165ba5'}}/>
												</IconButton>
											</Tooltip>
										) : (
											<Tooltip TransitionComponent={Zoom} placement={"top"} title="No Registrado">
												<IconButton aria-label="No Registrado">
													<Help style={{color: '#b9996f'}}/>
												</IconButton>
											</Tooltip>
										)}</TableCell>
									) : null}
									<TableCell align={'left'}>{item.empresa}</TableCell>
									<TableCell align={'left'}>{item.contacto_empresa}</TableCell>
									<TableCell align={'left'}>{item.telefono ? item.telefono : 'Sin registrar'}</TableCell>
									<TableCell align={'left'}>{item.celular}</TableCell>
									<TableCell align={'left'}>{item.correo_electronico}</TableCell>
									<TableCell align={'left'}>{item.cotizacion_estatus}</TableCell>
									<TableCell align={'right'} className={'padding-5-L padding-5-R'}>
										<div className={'w-auto vertical-inline'}>
											
											{Number(item.id_cat_cotizacion_estatus) >= 7 ? (
												<div className={'v-center'}>
													<ModalCandidatos
														componente={
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Candidatos/Evaluación">
																<IconButton aria-label="Candidatos/Evaluación">
																	{item.candidatos.length > 0 ? <GroupAdd style={{color: '#3fb459'}}/> : <GroupAdd/>}
																</IconButton>
															</Tooltip>
														}
														tipo={'add'}
														item={item}
														showSnackBars={this.props.showSnackBars}
														RefreshList={this.props.RefreshList}
													/>
												</div>
											) : null}
											
											{Number(item.id_cat_cotizacion_estatus) === 4 || Number(item.id_cat_cotizacion_estatus) === 7 ? (
												<div className={'v-center'}>
													<ModalComprobantePago
														tipo={'add'}
														item={item}
														componente={
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Subir Comprobante Pago">
																<IconButton aria-label="Subir Comprobante Pago" onClick={() => {
																}}>
																	{item.comprobantes_pago.length > 0 ? <LocalAtm style={{color: '#3fb459'}}/> : <LocalAtm/>}
																</IconButton>
															</Tooltip>
														}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
											) : null}
											
											{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
												<div className={'v-center'}>
													<ModalNube
														tipo={'add'}
														item={item}
														componente={
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Cargar Cotización">
																<IconButton aria-label="Cargar Cotización" onClick={() => {
																}}>
																	{item.cotizaciones.length > 0 ? <Description style={{color: '#3fb459'}}/> : <Description/>}
																</IconButton>
															</Tooltip>
														}
														RefreshList={this.props.RefreshList}
														showSnackBars={this.props.showSnackBars}
													/>
												</div>
											) : null}
											
											{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
												<div className={'v-center'}>
													<ModalSendCotizacion
														componente={
															<Tooltip TransitionComponent={Zoom} placement={"top"} title="Enviar Cotización">
																<IconButton aria-label="Enviar Cotización">
																	{item.enviado ? <MailOutline style={{color: '#3fb459'}}/> : <MailOutline/>}
																</IconButton>
															</Tooltip>
														}
														tipo={'add'}
														item={item}
														showSnackBars={this.props.showSnackBars}
														RefreshList={this.props.RefreshList}
													/>
												</div>
											) : null}
											
											<div className={'v-center'}>
												<ModalOptionsCotizacion
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Opciones Cotización">
															<IconButton aria-label="Opciones Cotización">
																{FieldsJs.inArray([4, 7], item.id_cat_cotizacion_estatus) ?
																	<LibraryAddCheck style={{color: '#3fb459'}}/>
																	: item.id_cat_cotizacion_estatus === 5 ?
																		<LibraryAddCheck style={{color: '#f2c94c'}}/> :
																		item.id_cat_cotizacion_estatus === 6 ?
																			<LibraryAddCheck style={{color: '#a51113'}}/> :
																			<LibraryAddCheck/>}
															</IconButton>
														</Tooltip>
													}
													tipo={'add'}
													item={item}
													showSnackBars={this.props.showSnackBars}
													RefreshList={this.props.RefreshList}
												/>
											</div>
											
											<div className={'v-center'}>
												<ModalChatCotizacion
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Contáctanos">
															<IconButton aria-label="Contáctanos" style={{backgroundColor: '#165ba5', borderRadius: '5px', padding: '6px'}}>
																<Badge anchorOrigin={{vertical: 'top', horizontal: 'right'}} style={{marginRight: '5px'}} badgeContent={item.sms_no_leidos} color="error">
																	<div className={'vertical-inline'}>
																		<img className={'v-center padding-6-R'} alt={'Chat'} src={icon_chat} width="25px" height="auto"/>
																		<div className={'v-center px-13 text-white'}>
																			Contáctanos
																		</div>
																	</div>
																</Badge>
																{/*<QuestionAnswer/>*/}
															</IconButton>
														</Tooltip>
													}
													tipo={'add'}
													item={item}
													showSnackBars={this.props.showSnackBars}
													RefreshList={this.props.RefreshList}
												/>
											</div>
											
											{/*<div className={'v-center'}>
											<ModalCotizacion
												tipo={'edit'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
										<div className={'v-center'}>
											<ModalCotizacion
												tipo={'view'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>*/}
										</div>
									</TableCell>
								</TableRow>
								
								{(
									<Fragment>
										{!!item.expandir ? (
											<TableRow>
												<TableCell align={'center'}
												           className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
												           colSpan={FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? 10 : 9}
												>
													
													<SubTablaCotizaciones
														item={item}
														showSnackBars={this.props.showSnackBars}
														RefreshList={this.props.RefreshList}
													/>
												
												</TableCell>
											</TableRow>
										) : null}
									</Fragment>
								)}
							</Fragment>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
};

export default ListaTabla;
