import React, {Component, Fragment} from 'react';

import './Home.css';
import logo_svg from '../../../assets/img/acresco_talento_gray.svg';
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import Grid from "@material-ui/core/Grid";
import {
	AccountBox,
	Cloud,
	Equalizer,
	Description,
	FindInPage,
	BrandingWatermark
} from "@material-ui/icons";

// import Button from "@material-ui/core/Button";

class Home extends Component {
	
	constructor(props) {
		super(props);
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	go = (u) => {
		this.props.history.push('/' + u);
	};
	
	render() {
		
		const bg_logo_svg = {
			backgroundImage: `url(${logo_svg})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundAttachment: 'fixed',
			backgroundSize: '300px auto',
			opacity: '1',
		};
		
		return (
			<div className='Home ContaineViewComponet' style={bg_logo_svg}>
				<Fragment>
					<Grid container spacing={2}>
						<Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
							<div className={'h-100-px w-100 btn-dash-home-1'} onClick={() => this.go('solicitud')}>
								<div className={'w-100 h-100 vertical-inline'}>
									<div className={'v-center w-100 text-white text-center'}>
										<BrandingWatermark style={{fontSize: '50px'}}/>
										<h4 className={'margin-0 padding-10-B'}>Generar Solicitud</h4>
									</div>
								</div>
							</div>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
							<div className={'h-100-px w-100 btn-dash-home-2'} onClick={() => this.go('cotizaciones')}>
								<div className={'w-100 h-100 vertical-inline'}>
									<div className={'v-center w-100 text-white text-center'}>
										<FindInPage style={{fontSize: '50px'}}/>
										<h4 className={'margin-0 padding-10-B'}>Ver Cotizaciones</h4>
									</div>
								</div>
							</div>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
							<div className={'h-100-px w-100 btn-dash-home-3'} onClick={() => this.go('facturacion')}>
								<div className={'w-100 h-100 vertical-inline'}>
									<div className={'v-center w-100 text-white text-center'}>
										<Description style={{fontSize: '50px'}}/>
										<h4 className={'margin-0 padding-10-B'}>Facturación</h4>
									</div>
								</div>
							</div>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
							<div className={'h-100-px w-100 btn-dash-home-4'} onClick={() => this.go('resultados')}>
								<div className={'w-100 h-100 vertical-inline'}>
									<div className={'v-center w-100 text-white text-center'}>
										<Equalizer style={{fontSize: '50px'}}/>
										<h4 className={'margin-0 padding-10-B'}>Resultados</h4>
									</div>
								</div>
							</div>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
							<div className={'h-100-px w-100 btn-dash-home-4'} >
								<div className={'w-100 h-100 vertical-inline'}>
									<div className={'v-center w-100 text-white text-center'}>
										<Cloud style={{fontSize: '50px'}}/>
										<h4 className={'margin-0 padding-10-B'}>Nube</h4>
									</div>
								</div>
							</div>
						</Grid>
						<Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
							<div className={'h-100-px w-100 btn-dash-home-4'} onClick={() => this.go('perfil')}>
								<div className={'w-100 h-100 vertical-inline'}>
									<div className={'v-center w-100 text-white text-center'}>
										<AccountBox style={{fontSize: '50px'}}/>
										<h4 className={'margin-0 padding-10-B'}>Perfil</h4>
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
				</Fragment>
				
				<div className={'w-auto content-image vertical-inline'}>
					<div className={'w-100 v-center'}>
						
						{/*
						<Button onClick={() => this.showSnackBars('info', 'Bienvenidos a Acresco Integraciones!!')}>
							Show
						</Button>
						*/}
					</div>
				</div>
			</div>
		);
	}
}

Home.propTypes = {};

export default IntegrationNotistack(Home);
