import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/index';
import TableBody from '@material-ui/core/TableBody/index';
import TableCell from '@material-ui/core/TableCell/index';
import TableHead from '@material-ui/core/TableHead/index';
import TableRow from '@material-ui/core/TableRow/index';
import Paper from '@material-ui/core/Paper/index';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {CotizacionService} from "../../../../services/_Sis/CotizacionService/CotizacionService";
import SubTablaCotizaciones from "./SubTablaCotizaciones";
import icon_mas from "../../../../assets/img/icons/expandir-mas.svg";
import icon_menos from "../../../../assets/img/icons/expandir-menos.svg";
import {MailOutline, CloudUpload, Description, PictureAsPdf} from "@material-ui/icons";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ModalSendFactura from "./ModalSendFactura";
import ModalNube from "./ModalNube";
import {FieldsJs} from "../../../../settings/General/General";


class ListaTabla extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar la cotización con id: ${item.id_cotizacion}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				CotizacionService.Eliminar(item.id_cotizacion).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	render() {
		
		const {lista} = this.props;
		const bg_mas = {
			backgroundImage: `url(${icon_mas})`
		};
		
		const bg_menos = {
			backgroundImage: `url(${icon_menos})`
		};
		return (
			<Paper style={{overflowX: "auto"}}>
				<Table className={'desing-acresco'}>
					<TableHead>
						<TableRow>
							{/*<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
								...
							</TableCell>*/}
							<TableCell component="th" align={'left'} className={'w-40-px padding-20-L padding-20-R'}>
								ID
							</TableCell>
							{/*{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
								<TableCell component="th" align={'left'}>Cliente</TableCell>
								):null}
							<TableCell component="th" align={'left'}>Empresa</TableCell>*/}
							<TableCell component="th" align={'left'}>Contacto Empresa</TableCell>
							{/*<TableCell component="th" align={'left'}>Teléfono</TableCell>
							<TableCell component="th" align={'left'}>Celular</TableCell>*/}
							<TableCell component="th" align={'left'}>Correo Electrónico</TableCell>
							<TableCell component="th" align={'left'}>Fecha</TableCell>
							<TableCell component="th" align={'left'}>Subtotal</TableCell>
							<TableCell component="th" align={'left'}>Iva</TableCell>
							<TableCell component="th" align={'left'}>Total</TableCell>
							<TableCell component="th" align={'left'}>Estatus</TableCell>
							<TableCell component="th" align={'left'}>Estatus Pago</TableCell>
							<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{lista.map((item, key) => (
							<Fragment key={key}>
								<TableRow>
									{/*<TableCell align={'left'} className={'padding-20-L padding-20-R'}>
										<div className={'w-100'} align={'center'}>
											{item.partidas.length > 0 ? (
												<div style={!!item.expandir ? bg_menos : bg_mas}
												     className={'bg-img-contain-x-center-y-center h-20-px w-20-px cursor-pointer'}
												     onClick={() => this.props.SubTabla(item, key)}
												/>
											) : (
												<div>...</div>
											)}
										
										</div>
									</TableCell>*/}
									<TableCell align={'left'} className={'padding-20-L padding-20-R'}>
										{item.id_facturacion}
									</TableCell>
									{/*{FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario) ? (
										<TableCell align={'left'}>{item.cliente}</TableCell>
									):null}
									<TableCell align={'left'}>{item.empresa}</TableCell>*/}
									<TableCell align={'left'}>{item.contacto_empresa}</TableCell>
									{/*<TableCell align={'left'}>{item.telefono ? item.telefono : 'Sin registrar'}</TableCell>
									<TableCell align={'left'}>{item.celular}</TableCell>*/}
									<TableCell align={'left'}>{item.correo_electronico}</TableCell>
									<TableCell align={'left'}>{item.fecha}</TableCell>
									<TableCell align={'left'}>{item.subtotal}</TableCell>
									<TableCell align={'left'}>{item.iva}</TableCell>
									<TableCell align={'left'}>{item.total}</TableCell>
									<TableCell align={'left'}>{item.facturacion_estatus}</TableCell>
									<TableCell align={'left'}>{item.facturacion_pago_estatus}</TableCell>
									<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
										<div className={'w-auto vertical-inline'}>
											<div className={'v-center'}>
												
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver Xml">
													<IconButton aria-label="Ver Xml" onClick={() => {}}>
														<Description/>
													</IconButton>
												</Tooltip>
											
											</div>
											
											<div className={'v-center'}>
												
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Ver PDF">
													<IconButton aria-label="Ver PDF" onClick={() => {}}>
														<PictureAsPdf/>
													</IconButton>
												</Tooltip>
											
											</div>
											<div className={'v-center'}>
												<ModalSendFactura
													componente={
														<Tooltip TransitionComponent={Zoom} placement={"top"} title="Enviar Factura">
															<IconButton aria-label="Enviar Factura">
																<MailOutline/>
															</IconButton>
														</Tooltip>
													}
													tipo={'add'}
													item={item}
													showSnackBars={this.props.showSnackBars}
													RefreshList={this.props.RefreshList}
												/>
											
											</div>
											{/*<div className={'v-center'}>
											<ModalCotizacion
												tipo={'edit'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>
										<div className={'v-center'}>
											<ModalCotizacion
												tipo={'view'}
												item={item}
												RefreshList={this.props.RefreshList}
												showSnackBars={this.props.showSnackBars}
											/>
										</div>*/}
										</div>
									</TableCell>
								</TableRow>
								
								{/*{(
									<Fragment>
										{!!item.expandir ? (
											<TableRow>
												<TableCell align={'center'}
												           className={'padding-20-L padding-20-R padding-20-T padding-20-B'}
												           colSpan={9}
												>
													
													<SubTablaCotizaciones
														item={item}
														showSnackBars={this.props.showSnackBars}
														RefreshList={this.props.RefreshList}
													/>
												
												</TableCell>
											</TableRow>
										) : null}
									</Fragment>
								)}*/}
							</Fragment>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

ListaTabla.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	SubTabla: PropTypes.func.isRequired,
};

export default ListaTabla;
