import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Grid from "@material-ui/core/Grid";

class Cotizando extends Component {
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
	}
	
	render() {
		
		const {lista} = this.props;
		
		return (
			<Grid container spacing={4}>
			
			</Grid>
		)
	}
}

Cotizando.propTypes = {
	lista: PropTypes.array.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default Cotizando;
