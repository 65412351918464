import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
	AccountBalanceWallet,
	AddOutlined,
	AlternateEmailOutlined,
	Business,
	CancelOutlined,
	DeleteOutlined,
	EditOutlined,
	ExitToApp,
	GroupAdd,
	HdrStrongOutlined,
	HdrWeakOutlined,
	ListAlt,
	PermIdentity,
	Person,
	PhoneIphoneOutlined,
	PhoneOutlined,
	SaveOutlined,
	Work
} from "@material-ui/icons";
import logo_svg from '../../assets/img/fondo-00.jpg';
import login_background from '../../assets/img/fondo-00.jpg';
import img_logo from '../../assets/img/acresco_talento.svg';

import Button from "@material-ui/core/Button";
import ModalLogin from "./Includes/ModalLogin";

import {Element} from "react-scroll";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import "./LandingPage.css";
import {EnteroSolo, FieldsJs} from "../../settings/General/General";
import TextField from "@material-ui/core/TextField";
import {KeyboardDateTimePicker, DatePicker, TimePicker } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {CatService} from "../../services/_Cat/CatService/CatService";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import VistaVacia from "../Include/VistaVacia/VistaVacia";
import carrito_de_compra_3 from "../../assets/img/icons/contacto_tarjeta.svg";
import moment from "moment";
import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../settings/IntegrationNotistack/IntegrationNotistack";
import {PopupService} from "../../settings/PoPup/PoPup";
import {CONFIG} from "../../settings/Config/Config";
import {CotizacionService} from "../../services/_Sis/CotizacionService/CotizacionService";
import ModalRegister from "./Includes/ModalRegister";
import ModalCotizacionWelcome from "./Includes/ModalCotizacionWelcome";

const drawerWidth = 240;

const styles = theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('xs')]: {
			display: 'none',
		},
	},
	
	title: {
		flexGrow: 1,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		//padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		// marginLeft: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	textField: {
		color: 'white',
		borderColor: 'white',
		'& label.Mui-focused': {
			color: 'white',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'white',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'white',
			},
			'&:hover fieldset': {
				borderColor: 'white',
			},
			'&.Mui-focused fieldset': {
				borderColor: 'white',
			},
		},
		"& .MuiFormLabel-root": {
			color: "#ccc"
		}
	},
	input: {
		color: 'white',
	},
});

class LandingPage extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false,
				open_welcome: false
			},
			open: false,
			
			tipo: props.tipo,
			
			id_cotizacion: (props.item || {}).id_cotizacion,
			folio: '',
			empresa: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			telefono: '',
			celular: '',
			correo_electronico: '',
			id_evaluacion: '',
			evaluacion: '',
			posicion: '',
			cantidad: '',
			id_cat_estado: '',
			id_cat_estado_empresa: '',
			estado: '',
			id_cat_municipio_empresa: '',
			id_cat_municipio: '',
			municipio: '',
			fecha_solicitud: null,
			hora_solicitud: null,
			
			activo: true,
			
			cat_estado: [],
			cat_municipio: [],
			cat_municipio_entidad: [],
			cat_tipo_evaluacion: [],
			lista_cotizaciones: [],
			
			key: null,
		};
		
		
		this.showSnackBars = this.showSnackBars.bind(this);
		this.ListEstado();
		this.ListTipoEvaluacion();
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
		
	};
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	is_edited_cotizacion = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	edit_cotizacion = (value, key) => {
		try {
			
			this.setState({
				key: key,
				id_evaluacion: value.id_evaluacion,
				evaluacion: value.evaluacion,
				posicion: value.posicion,
				cantidad: value.cantidad,
				id_cat_estado: value.id_cat_estado,
				estado: value.estado,
				id_cat_municipio: value.id_cat_municipio,
				municipio: value.municipio,
				fecha_solicitud: moment(value.fecha_solicitud),
				hora_solicitud: moment(value.fecha_solicitud + ' ' + value.hora_solicitud),
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_evaluacion: '',
			evaluacion: '',
			posicion: '',
			cantidad: '',
			id_cat_estado: '',
			estado: '',
			id_cat_municipio: '',
			municipio: '',
			fecha_solicitud: null,
			hora_solicitud: null,
		});
	};
	
	
	add_cotizacion = () => {
		try {
			let cotizacion = FieldsJs.Copy(this.state.lista_cotizaciones);
			if (!this.state.id_evaluacion) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Evaluación.'
				})
			}
			
			if (!this.state.posicion) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Posición del candidato.'
				})
			}
			
			if (!this.state.cantidad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}
			
			if (!this.state.id_cat_estado) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Entidad.'
				})
			}
			
			if (!this.state.id_cat_municipio) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Municipio.'
				})
			}
			
			if (!this.state.fecha_solicitud) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Fecha.'
				})
			}
			
			if (!this.state.hora_solicitud) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Hora.'
				})
			}
			
			console.log('this.state.fecha_solicitud', this.state.fecha_solicitud);
			
			
			if (this.is_edited_cotizacion()) {
				for (let i = 0; i < cotizacion.length; i++) {
					let item = cotizacion[i];
					let evaluacion = '';
					for (let j = 0; j < this.state.cat_tipo_evaluacion.length; j++) {
						if (Number(item.id_evaluacion) === Number(this.state.cat_tipo_evaluacion[j].id_cat_tipo_evaluacion)) {
							evaluacion = this.state.cat_tipo_evaluacion[j].tipo_evaluacion;
						}
					}
					if (this.state.key === i) {
						item.id_evaluacion = this.state.id_evaluacion || null;
						item.evaluacion = evaluacion;
						item.posicion = this.state.posicion || null;
						item.cantidad = this.state.cantidad || null;
						item.id_cat_estado = this.state.id_cat_estado || null;
						item.estado = this.state.estado || null;
						item.id_cat_municipio = this.state.id_cat_municipio || null;
						item.municipio = this.state.municipio || null;
						item.requerimiento = this.state.requerimiento || null;
						item.cotizacion = this.state.cotizacion || null;
						item.fecha_solicitud = this.state.fecha_solicitud != null ? moment(this.state.fecha_solicitud).format("YYYY-MM-DD") : null;
						item.hora_solicitud = this.state.hora_solicitud != null ? moment(this.state.hora_solicitud).format("HH:mm") : null;
					}
				}
			} else {
				let item;
				let evaluacion = '';
				for (let j = 0; j < this.state.cat_tipo_evaluacion.length; j++) {
					if (Number(this.state.id_evaluacion) === Number(this.state.cat_tipo_evaluacion[j].id_cat_tipo_evaluacion)) {
						evaluacion = this.state.cat_tipo_evaluacion[j].tipo_evaluacion;
					}
				}
				item = {
					id_evaluacion: this.state.id_evaluacion || null,
					evaluacion: evaluacion,
					posicion: this.state.posicion || null,
					cantidad: this.state.cantidad || null,
					id_cat_estado: this.state.id_cat_estado || null,
					estado: this.state.estado || null,
					id_cat_municipio: this.state.id_cat_municipio || null,
					municipio: this.state.municipio || null,
					requerimiento: this.state.requerimiento || null,
					cotizacion: this.state.cotizacion || null,
					fecha_solicitud: this.state.fecha_solicitud != null ? moment(this.state.fecha_solicitud).format("YYYY-MM-DD") : null,
					hora_solicitud: this.state.hora_solicitud != null ? moment(this.state.hora_solicitud).format("HH:mm") : null,
				};
				cotizacion.push(item);
			}
			
			this.setState({
				key: null,
				lista_cotizaciones: cotizacion,
				id_evaluacion: '',
				evaluacion: '',
				posicion: '',
				cantidad: '',
				id_cat_estado: '',
				estado: '',
				id_cat_municipio: '',
				municipio: '',
				fecha_solicitud: null,
				hora_solicitud: null,
			});
			
		} catch (e) {
			console.error(e);
			this.showSnackBars('error', e.mensaje);
		}
		
		console.log(this.state.cotizacion);
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar la cotización?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_cotizaciones = FieldsJs.Copy(this.state.lista_cotizaciones);
					
					lista_cotizaciones.splice(key, 1);
					
					this.setState({
						lista_cotizaciones: lista_cotizaciones
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	ListTipoEvaluacion = () => {
		CatService.ListTipoEvaluacion().then((response) => {
			this.setState({
				cat_tipo_evaluacion: response.data
			});
		});
	};
	
	ListEstado = () => {
		CatService.ListEstado().then((response) => {
			this.setState({
				cat_estado: response.data
			});
		});
	};
	
	ListMunicipio = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		
		for (let i = 0; i < this.state.cat_estado.length; i++) {
			if (Number(this.state.cat_estado[i].id_cat_estado) === Number(id_cat_estado)) {
				this.setState({
					estado: this.state.cat_estado[i].estado
				});
			}
		}
		
		CatService.ListMunicipio(id_cat_estado).then((response) => {
			this.setState({
				cat_municipio: response.data
			});
		});
	};
	
	ListMunicipioEntidad = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		
		for (let i = 0; i < this.state.cat_estado.length; i++) {
			if (Number(this.state.cat_estado[i].id_cat_estado) === Number(id_cat_estado)) {
				this.setState({
					estado: this.state.cat_estado[i].estado
				});
			}
		}
		
		CatService.ListMunicipio(id_cat_estado).then((response) => {
			this.setState({
				cat_municipio_entidad: response.data
			});
		});
	};
	
	
	handleDrawerToggle = () => {
		this.setState({open: !this.state.open});
	};
	
	clearForm = () => {
		this.setState({
			id_cotizacion: '',
			empresa: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			puesto: '',
			telefono: '',
			celular: '',
			correo_electronico: '',
			id_evaluacion: '',
			evaluacion: '',
			posicion: '',
			cantidad: '',
			id_cat_estado: '',
			id_cat_estado_empresa: '',
			estado: '',
			id_cat_municipio: '',
			id_cat_municipio_empresa: '',
			municipio: '',
			fecha_solicitud: null,
			
			activo: true,
			lista_cotizaciones: [],
			
			key: null,
		});
	};
	
	
	save = () => {
		if (this.state.id_cotizacion > 0) {
			CotizacionService.Modificar(this.state).then((response) => {
				this.openModalWelcome();
				this.clearForm();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			CotizacionService.Agregar(this.state).then((response) => {
				this.showSnackBars('success', response.mensaje);
				this.setState({folio: response.data.folio, correo: this.state.correo_electronico});
				this.openModalWelcome();
				this.clearForm();
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	openModalWelcome = () => {
		this.setState({
			modal: {
				open: false,
				open_welcome: true
			}
		});
	};
	
	render() {
		const {classes, children} = this.props;
		
		const bg_logo_svg = {
			backgroundImage: `url(${logo_svg})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundAttachment: 'fixed',
			backgroundSize: '300px auto',
			opacity: '1',
		};
		
		const bg_fondo = {
			backgroundImage: `url(${login_background})`,
		};
		
		
		return (
			<div className={classes.root}>
				<CssBaseline/>
				<AppBar
					position="fixed"
					className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
				>
					<Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
						<IconButton
							color="inherit"
							aria-label="Open drawer"
							onClick={this.handleDrawerToggle}
							className={classNames(
								classes.menuButton,
								this.state.open && classes.menuButtonHidden,
							)}
						>
							<MenuIcon/>
						</IconButton>
						<img src={img_logo} alt={'Logo'} width={'130px'} height={'40px'} className={'margin-15-L'}/>
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							align={"center"}
							className={classes.title}
						>
						</Typography>
						<Hidden xsDown>
							<div className={'vertical-inline'}>
								{/*<div className={'v-center'}>
									<ModalCotizacion
										tipo={'add'}
										item={{}}
										componente={<Button color="inherit">Cotización</Button>}
									/>
								</div>*/}
								
								<div className={'v-center padding-10-R'}>
									<ModalRegister
										tipo={'add'}
										item={{}}
										componente={
											<Button
												style={{backgroundColor: 'white !important'}}
												variant="contained"
												disabled
												color="default"
												className={classes.button}
												startIcon={<AccountBalanceWallet/>}
											>Regístrate</Button>
										}
										showSnackBars={this.showSnackBars}
										cat_estado={this.state.cat_estado}
									/>
								</div>
								
								<div className={'v-center padding-10-R'}>
									<ModalLogin
										tipo={'add'}
										item={{}}
										componente={
											<Button
												style={{backgroundColor: 'white !important'}}
												variant="contained"
												disabled
												color="default"
												className={classes.button}
												startIcon={<ExitToApp/>}
											>Iniciar Sesión</Button>
										}
										showSnackBars={this.showSnackBars}
									/>
								</div>
							</div>
						</Hidden>
					
					</Toolbar>
				</AppBar>
				<Drawer
					variant="persistent"
					classes={{
						paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
					}}
					open={this.state.open}
				>
					<div className={classes.drawerHeader}>
						<IconButton onClick={this.handleDrawerToggle}>
							<ChevronLeftIcon/>
						</IconButton>
					</div>
					<Divider/>
					<List>
						<ListItem button>
							<ListItemText primary={"Quiénes Somos"}/>
						</ListItem>
						<ListItem button>
							<ListItemText primary={"Contáctanos"}/>
						</ListItem>
						<ListItem button>
							<ListItemText primary={"Cotización"}/>
						</ListItem>
					</List>
					
					<Divider/>
					
					<List>
						<ListItem button>
							<ListItemIcon><ExitToApp/></ListItemIcon>
							<ListItemText primary={"Iniciar Sesión"}/>
						</ListItem>
					
					</List>
					{/*<Divider/>
					<List>
						{['All mail', 'Trash', 'Spam'].map((text, index) => (
							<ListItem button key={text}>
								<ListItemIcon>{index % 2 === 0 ? <Inbox/> : <Mail/>}</ListItemIcon>
								<ListItemText primary={text}/>
							</ListItem>
						))}
					</List>*/}
					<Divider/>
				</Drawer>
				<main className={clsx(classes.content, {[classes.contentShift]: this.state.open,})}>
					<div className={classes.drawerHeader}/>
					
					{this.state.modal.open_welcome ? (
						<ModalCotizacionWelcome item={this.state} open={true}/>
					) : null}
					
					{/* LANDING */}
					
					<div className='LandingPage ContaineViewComponetLanding bg-img-cover-x-center-y-center' style={bg_fondo}>
						<div className={'w-100 content-image vertical-inline'}>
							<div className={'w-100 v-center'}>
								<Element name="quienes_somos" className={'padding-50-T w-100'}>
									<Grid container spacing={2}>
										<Grid item xs={1}/>
										
										<Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
											<Grid container spacing={2} className={'h-100'}>
												<Grid item xs={4} className={'h-100 logoformempresa text-white'} style={{borderRadius: '10px 0px 0px 10px', backgroundColor: 'rgba(0,0,0,0.5)'}}>
													<Grid container spacing={2}>
														<Grid item className={'text-center'} xs={12}>
															<Typography className={'px-20'}>
																Información de la Empresa
															</Typography>
														</Grid>
														
														
														<Grid item className={'text-left'} xs={12}>
															<Grid container spacing={1} alignItems={"flex-end"}>
																<Grid item className={'w-30-px'}>
																	<Business className={'w-100 text-gray'}/>
																</Grid>
																<Grid item className={'w-100-30-px'}>
																	<TextField
																		margin="dense"
																		name="empresa"
																		label="Nombre de la empresa"
																		type="text"
																		fullWidth
																		required
																		value={this.state.empresa}
																		onChange={this.handleChange}
																		disabled={this.state.tipo === 'view'}
																		className={classes.textField}
																		InputProps={{
																			classes: {
																				input: classes.input
																			}
																		}}
																	/>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
															<Grid container spacing={1} alignItems={"flex-end"}>
																<Grid item className={'w-30-px'}>
																	<HdrWeakOutlined className={'w-100 text-gray'}/>
																</Grid>
																<Grid item className={'w-100-30-px'}>
																	<FormControl fullWidth required className={classes.textField}>
																		<InputLabel>Estado</InputLabel>
																		<Select
																			native
																			value={this.state.id_cat_estado_empresa}
																			onChange={(e) => {
																				this.handleChange(e);
																				this.ListMunicipio(e);
																			}}
																			name="id_cat_estado_empresa"
																			input={<Input/>}
																			className={classes.textField}
																			InputProps={{
																				classes: {
																					input: classes.input
																				}
																			}}
																		>
																			<option value=""/>
																			{this.state.cat_estado.map(option => (
																				<option key={option.id_cat_estado}
																				        value={option.id_cat_estado}>
																					{option.estado}
																				</option>
																			))}
																		
																		</Select>
																	</FormControl>
																</Grid>
															</Grid>
														</Grid>
														
														
														<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
															<Grid container spacing={1} alignItems={"flex-end"}>
																<Grid item className={'w-30-px'}>
																	<HdrStrongOutlined className={'w-100 text-gray'}/>
																</Grid>
																<Grid item className={'w-100-30-px'}>
																	
																	<FormControl fullWidth required className={classes.textField}>
																		<InputLabel>Municipio</InputLabel>
																		<Select
																			native
																			value={this.state.id_cat_municipio_empresa}
																			onChange={this.handleChange}
																			name="id_cat_municipio_empresa"
																			input={<Input/>}
																			className={classes.textField}
																			InputProps={{
																				classes: {
																					input: classes.input
																				}
																			}}
																		>
																			<option value=""/>
																			{this.state.cat_municipio.map(option => (
																				<option key={option.id_cat_municipio}
																				        value={option.id_cat_municipio}>
																					{option.municipio}
																				</option>
																			))}
																		
																		</Select>
																	</FormControl>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item className={'text-left'} xs={12}>
															<Typography className={'px-14 margin-10-T padding-5-L'}>
																Información del contacto
															</Typography>
														</Grid>
														
														<Grid item className={'text-left'} xs={6}>
															<Grid container spacing={1} alignItems={"flex-end"}>
																<Grid item className={'w-30-px'}>
																	<Person className={'w-100 text-gray'}/>
																</Grid>
																<Grid item className={'w-100-30-px'}>
																	<TextField
																		margin="dense"
																		name="nombre"
																		label="Nombre"
																		type="text"
																		fullWidth
																		required
																		value={this.state.nombre}
																		onChange={this.handleChange}
																		disabled={this.state.tipo === 'view'}
																		className={classes.textField}
																		InputProps={{
																			classes: {
																				input: classes.input
																			}
																		}}
																	/>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item className={'text-left'} xs={6}>
															<Grid container spacing={1} alignItems={"flex-end"}>
																<Grid item className={'w-30-px'}>
																	<Person className={'w-100 text-gray'}/>
																</Grid>
																<Grid item className={'w-100-30-px'}>
																	<TextField
																		margin="dense"
																		name="apellido_paterno"
																		label="Apellido Paterno"
																		type="text"
																		fullWidth
																		required
																		value={this.state.apellido_paterno}
																		onChange={this.handleChange}
																		disabled={this.state.tipo === 'view'}
																		className={classes.textField}
																		InputProps={{
																			classes: {
																				input: classes.input
																			}
																		}}
																	/>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item className={'text-left'} xs={6}>
															<Grid container spacing={1} alignItems={"flex-end"}>
																<Grid item className={'w-30-px'}>
																	<Person className={'w-100 text-gray'}/>
																</Grid>
																<Grid item className={'w-100-30-px'}>
																	<TextField
																		margin="dense"
																		name="apellido_materno"
																		label="Apellido Materno"
																		type="text"
																		fullWidth
																		value={this.state.apellido_materno}
																		onChange={this.handleChange}
																		disabled={this.state.tipo === 'view'}
																		className={classes.textField}
																		InputProps={{
																			classes: {
																				input: classes.input
																			}
																		}}
																	/>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
															<Grid container spacing={1} alignItems={"flex-end"}>
																<Grid item className={'w-30-px'}>
																	<Work className={'w-100 text-gray'}/>
																</Grid>
																<Grid item className={'w-100-30-px'}>
																	<TextField
																		margin="dense"
																		type={'text'}
																		fullWidth
																		required
																		name="puesto"
																		onChange={this.handleChange}
																		label="Puesto"
																		value={this.state.puesto}
																		className={classes.textField}
																		disabled={this.state.tipo === 'view'}
																		InputProps={{
																			classes: {
																				input: classes.input
																			}
																		}}
																	/>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item className={'text-left'} xs={6}>
															<Grid container spacing={1} alignItems={"flex-end"}>
																<Grid item className={'w-30-px'}>
																	<PhoneOutlined className={'w-100 text-gray'}/>
																</Grid>
																<Grid item className={'w-100-30-px'}>
																	<TextField
																		type={'text'}
																		fullWidth
																		name="telefono"
																		onChange={this.handleChange}
																		inputProps={{maxLength: 10}}
																		onKeyPress={EnteroSolo}
																		label="Teléfono" autoComplete={'off'}
																		value={this.state.telefono}
																		disabled={this.props.tipo === 'view'}
																		className={classes.textField}
																		InputProps={{
																			classes: {
																				input: classes.input
																			}
																		}}
																	/>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item className={'text-left'} xs={6}>
															<Grid container spacing={1} alignItems={"flex-end"}>
																<Grid item className={'w-30-px'}>
																	<PhoneIphoneOutlined className={'w-100 text-gray'}/>
																</Grid>
																<Grid item className={'w-100-30-px'}>
																	<TextField type={'text'} fullWidth name="celular"
																	           onChange={this.handleChange}
																	           inputProps={{maxLength: 10}}
																	           onKeyPress={EnteroSolo}
																	           label="Celular" autoComplete={'off'}
																	           value={this.state.celular}
																	           required
																	           disabled={this.props.tipo === 'view'}
																	           className={classes.textField}
																	           InputProps={{
																		           classes: {
																			           input: classes.input
																		           }
																	           }}
																	/>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item className={'text-left'} xs={12}>
															<Grid container spacing={1} alignItems={"flex-end"}>
																<Grid item className={'w-30-px'}>
																	<AlternateEmailOutlined className={'w-100 text-gray'}/>
																</Grid>
																<Grid item className={'w-100-30-px'}>
																	<TextField type={'text'} fullWidth name="correo_electronico"
																	           onChange={this.handleChange}
																	           label="Correo electrónico" autoComplete={'off'}
																	           value={this.state.correo_electronico}
																	           required
																	           disabled={this.props.tipo === 'view'}
																	           className={classes.textField}
																	           InputProps={{
																		           classes: {
																			           input: classes.input
																		           }
																	           }}
																	/>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
												
												
												<Grid item xs={8} className={'h-100'} style={{borderRadius: '0px 10px 10px 0px', backgroundColor: 'rgba(255,255,255,0.7)', border: '1px solid #ccc'}}>
													<Grid container spacing={2} alignItems={"flex-end"}>
														<Grid item xs={12} align={'center'}>
															<Typography className={'px-20'}>
																Solicitud de Cotización
															</Typography>
														</Grid>
														
														<Grid item className={'text-left'} xs={10}>
															<Grid container spacing={1} alignItems={"flex-end"}>
																
																<Grid item className={'text-left'} xs={4}>
																	<Grid container spacing={1} alignItems={"flex-end"}>
																		<Grid item className={'w-30-px'}>
																			<ListAlt className={'w-100 text-gray'}/>
																		</Grid>
																		<Grid item className={'w-100-30-px'}>
																			
																			<FormControl fullWidth required>
																				<InputLabel>Evaluación</InputLabel>
																				<Select
																					native
																					value={this.state.id_evaluacion}
																					onChange={this.handleChange}
																					name="id_evaluacion"
																					input={<Input/>}
																					disabled={this.props.tipo === 'view'}
																				>
																					<option value=""/>
																					{this.state.cat_tipo_evaluacion.map(option => (
																						<option key={option.id_cat_tipo_evaluacion}
																						        value={option.id_cat_tipo_evaluacion}>
																							{option.tipo_evaluacion}
																						</option>
																					))}
																				
																				</Select>
																			</FormControl>
																		</Grid>
																	</Grid>
																</Grid>
																
																<Grid item className={'text-left'} xs={4}>
																	<Grid container spacing={1} alignItems={"flex-end"}>
																		<Grid item className={'w-30-px'}>
																			<PermIdentity className={'w-100 text-gray'}/>
																		</Grid>
																		<Grid item className={'w-100-30-px'}>
																			<TextField
																				required
																				name="posicion"
																				label="Posición del candidato"
																				type="text"
																				fullWidth
																				value={this.state.posicion}
																				onChange={this.handleChange}
																			/>
																		</Grid>
																	</Grid>
																</Grid>
																
																<Grid item className={'text-left'} xs={4}>
																	<Grid container spacing={1} alignItems={"flex-end"}>
																		<Grid item className={'w-30-px'}>
																			<GroupAdd className={'w-100 text-gray'}/>
																		</Grid>
																		<Grid item className={'w-100-30-px'}>
																			<TextField type={'text'} fullWidth name="cantidad"
																			           onChange={this.handleChange}
																			           required
																			           inputProps={{maxLength: 10}}
																			           onKeyPress={EnteroSolo}
																			           label="Cantidad" autoComplete={'off'}
																			           value={this.state.cantidad}
																			/>
																		</Grid>
																	</Grid>
																</Grid>
																
																<Grid item className={'text-left'} xs={4}>
																	<Grid container spacing={1} alignItems={"flex-end"}>
																		<Grid item className={'w-30-px'}>
																			<HdrWeakOutlined className={'w-100 text-gray'}/>
																		</Grid>
																		<Grid item className={'w-100-30-px'}>
																			<FormControl fullWidth required>
																				<InputLabel>Entidad</InputLabel>
																				<Select
																					native
																					value={this.state.id_cat_estado}
																					onChange={(e) => {
																						this.handleChange(e);
																						this.ListMunicipioEntidad(e);
																					}}
																					name="id_cat_estado"
																					input={<Input/>}
																					disabled={this.props.tipo === 'view'}
																				>
																					<option value=""/>
																					{this.state.cat_estado.map(option => (
																						<option key={option.id_cat_estado}
																						        value={option.id_cat_estado}>
																							{option.estado}
																						</option>
																					))}
																				
																				</Select>
																			</FormControl>
																		</Grid>
																	</Grid>
																</Grid>
																
																
																<Grid item className={'text-left'} xs={4}>
																	<Grid container spacing={1} alignItems={"flex-end"}>
																		<Grid item className={'w-30-px'}>
																			<HdrStrongOutlined className={'w-100 text-gray'}/>
																		</Grid>
																		<Grid item className={'w-100-30-px'}>
																			
																			<FormControl fullWidth required>
																				<InputLabel>Municipio</InputLabel>
																				<Select
																					native
																					value={this.state.id_cat_municipio}
																					onChange={(e) => {
																						this.handleChange(e);
																						for (let i = 0; i < this.state.cat_municipio_entidad.length; i++) {
																							if (Number(this.state.cat_municipio_entidad[i].id_cat_municipio) === Number(e.target.value)) {
																								this.setState({
																									municipio: this.state.cat_municipio_entidad[i].municipio
																								});
																							}
																						}
																					}}
																					name="id_cat_municipio"
																					input={<Input/>}
																					disabled={this.props.tipo === 'view'}
																				>
																					<option value=""/>
																					{this.state.cat_municipio_entidad.map(option => (
																						<option key={option.id_cat_municipio}
																						        value={option.id_cat_municipio}>
																							{option.municipio}
																						</option>
																					))}
																				
																				</Select>
																			</FormControl>
																		</Grid>
																	</Grid>
																</Grid>
																
																<Grid item className={'text-left'} xs={2}>
																	<DatePicker
																		format="yyyy/MM/dd"
																		fullWidth
																		clearable
																		required
																		inputProps={{readOnly: true}}
																		KeyboardButtonProps={{
																			'aria-label': 'Fecha',
																		}}
																		label="Fecha"
																		value={this.state.fecha_solicitud}
																		onChange={(date) => {
																			this.handleChange(null, null, null, date, 'fecha_solicitud');
																		}}
																		minDate={moment(new Date()).add(1, 'days')}
																		clearLabel={'Limpiar'}
																		okLabel={'Aceptar'}
																		cancelLabel={'Cancelar'}
																		onError={console.log}
																	/>
																</Grid>
																
																<Grid item className={'text-left'} xs={2}>
																	<TimePicker
																		clearable
																		required
																		format="hh:mm"
																		label="Hora"
																		value={this.state.hora_solicitud}
																		onChange={(date) => {
																			this.handleChange(null, null, null, date, 'hora_solicitud');
																		}}
																		clearLabel={'Limpiar'}
																		okLabel={'Aceptar'}
																		cancelLabel={'Cancelar'}
																		onError={console.log}
																	/>
																</Grid>
															</Grid>
														</Grid>
														
														<Grid item xs={2} sm={2} md={2} lg={2} xl={2} align={"right"}>
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title="Cancelar edición"
																children={(
																	<span>
																			<Fab size="small" color="primary"
																			     aria-label="Cancelar edición"
																			     onClick={() => this.edit_cancel()}
																			     style={{marginLeft: "15px"}}
																			     disabled={!this.is_edited_cotizacion()}>
																				<CancelOutlined/>
																			</Fab>
																		</span>
																)}
															/>
															<Tooltip
																TransitionComponent={Zoom}
																placement={"top"}
																title={this.is_edited_cotizacion() ? "Actualizar" : "Agregar"}
																children={(
																	<Fab size="small" color="primary"
																	     aria-label={this.is_edited_cotizacion() ? "Actualizar" : "Agregar"}
																	     onClick={() => this.add_cotizacion()}
																	     style={{marginLeft: "15px"}}>
																		{this.is_edited_cotizacion() ? (
																			<SaveOutlined/>
																		) : (
																			<AddOutlined/>
																		)}
																	</Fab>
																)}
															/>
														</Grid>
														
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<Divider className={'margin-10-T margin-10-B'}/>
														</Grid>
														
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
															<div className={'min-h-350-px'} style={{backgroundColor: 'rgba(255, 255, 255, 0.5)'}}>
																<table className={'desing-talento'}>
																	<thead>
																	<tr>
																		<th>Evaluación</th>
																		<th>Posición</th>
																		<th>Cantidad</th>
																		<th>Entidad</th>
																		<th>Municipio</th>
																		<th>Fecha/Hora</th>
																		<th>Acciones</th>
																	</tr>
																	</thead>
																	<tbody>
																	{this.state.lista_cotizaciones.map((value, index) => (
																			<tr key={index}>
																				<td>{value.evaluacion}</td>
																				<td>{value.posicion}</td>
																				<td>{value.cantidad}</td>
																				<td>{value.estado}</td>
																				<td>{value.municipio}</td>
																				<td>{value.fecha_solicitud+'/'+value.hora_solicitud}</td>
																				<td align={'right'}>
																					<Tooltip
																						TransitionComponent={Zoom}
																						placement={"top"}
																						title="Eliminar"
																						children={(
																							<Fab
																								size="small"
																								aria-label="Eliminar"
																								onClick={() => this.delete(value, index)}
																								style={{
																									marginLeft: "10px",
																									backgroundColor: "transparent",
																									color: "#808080",
																									boxShadow: "none",
																								}}
																								children={(
																									<DeleteOutlined/>
																								)}
																							/>
																						)}
																					/>
																					{this.state.key === index ? (
																						<Tooltip
																							TransitionComponent={Zoom}
																							placement={"top"}
																							title="Producto en edición"
																							children={(
																								<Fab
																									size="small"
																									aria-label="Editar"
																									onClick={() => this.edit_cotizacion(value, index)}
																									style={{
																										marginLeft: "10px",
																										backgroundColor: "#f50057",
																										color: "white",
																									}}
																									children={(
																										<EditOutlined/>
																									)}
																								/>
																							)}
																						/>
																					) : (
																						<Tooltip
																							TransitionComponent={Zoom}
																							placement={"top"}
																							title="Editr producto"
																							children={(
																								<Fab
																									size="small"
																									aria-label="Editar"
																									onClick={() => this.edit_cotizacion(value, index)}
																									style={{
																										marginLeft: "10px",
																										backgroundColor: "transparent",
																										color: "#808080",
																										boxShadow: "none",
																									}}
																									children={(
																										<EditOutlined/>
																									)}
																								/>
																							)}
																						/>
																					)}
																				</td>
																			</tr>
																		)
																	)}
																	{!(this.state.lista_cotizaciones.length > 0) ? (
																		<tr>
																			<td colSpan={12}
																			    align={'center'}>
																				<VistaVacia
																					numero={null}
																					mensaje={'No se han agregado cotizaciones.'}
																					image={carrito_de_compra_3}
																					padding={'10px'}
																					paddingText={'10px'}
																					height={'60px'}
																					width={'60px'}
																				/>
																			</td>
																		</tr>
																	) : null}
																	</tbody>
																</table>
															</div>
														</Grid>
														
														<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'right'}>
															<div className={'padding-15-B'}>
																<Button variant="contained" color="primary" size="large" onClick={this.save} className={'btn-default-primary text-transform-none'}>Enviar solicitud</Button>
															</div>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										
										</Grid>
									</Grid>
								</Element>
							
							</div>
						</div>
					</div>
				</main>
			</div>
		);
	}
}

LandingPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default IntegrationNotistack(withStyles(styles)(LandingPage));
