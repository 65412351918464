import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

export const FacturacionService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				folio: filtro.folio || null,
				id_cat_ticket_status: filtro.empresa || null,
				id_cat_tipo_usuario: filtro.id_cat_tipo_usuario|| null,
				id_cat_cotizacion_estatus: filtro.id_cat_cotizacion_estatus|| null,
				celular: filtro.celular|| null,
				correo_electronico: filtro.correo_electronico|| null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Facturacion_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarArchivo: (form) => {
		let params = {
			id_cotizacion: form.id_cotizacion,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Facturacion_Datos_Archivo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_solicitud = form.fecha_solicitud ? DateFormat.FormatSql(form.fecha_solicitud):'';
		
		let params = {
			id_cotizacion: form.id_cotizacion,
			id_usuario : form.id_usuario,
			empresa : form.empresa,
			contacto_empresa : form.contacto_empresa,
			telefono : form.telefono,
			celular : form.celular,
			correo_electronico : form.correo_electronico,
			cotizaciones: form.lista_cotizaciones,
			/*id_evaluacion : form.id_evaluacion,
			posicion : form.posicion,
			cantidad : form.cantidad,
			id_cat_estado : form.id_cat_estado,
			id_cat_municipio : form.id_cat_municipio,
			fecha_solicitud : fecha_solicitud,*/
			activo : 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Facturacion_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarArchivo: (form) => {
		let params = {
			id_cotizacion: form.id_cotizacion,
			archivos: form.archivo,
			activo : 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Facturacion_Guardar_Archivo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_solicitud = form.fecha_solicitud ? DateFormat.FormatSql(form.fecha_solicitud):'';
		
		let params = {
			id_cotizacion: form.id_cotizacion,
			empresa : form.empresa,
			contacto_empresa : form.contacto_empresa,
			telefono : form.telefono,
			celular : form.celular,
			correo_electronico : form.correo_electronico,
			cotizaciones: form.lista_cotizaciones,
			/*id_evaluacion : form.id_evaluacion,
			posicion : form.posicion,
			cantidad : form.cantidad,
			id_cat_estado : form.id_cat_estado,
			id_cat_municipio : form.id_cat_municipio,
			fecha_solicitud : fecha_solicitud,*/
			activo : 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Facturacion_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_orientacion) => {let params = {
			id_orientacion: id_orientacion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Facturacion_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SendEmail: (form) => {let params = {
			id_cotizacion_archivo: form.selected_radio,
			correo_electronico: form.correo_electronico
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Facturacion_Enviar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
