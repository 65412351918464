import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';
import {DateFormat} from "../../../settings/DateFormat/DateFormat";

export const CotizacionService = {
	Listar: (filtro, paginacion) => {
		let params = {
			filtro: {
				folio: filtro.folio || null,
				id_cat_ticket_status: filtro.empresa || null,
				id_cat_tipo_usuario: filtro.id_cat_tipo_usuario|| null,
				id_cat_cotizacion_estatus: filtro.id_cat_cotizacion_estatus|| null,
				celular: filtro.celular|| null,
				correo_electronico: filtro.correo_electronico|| null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarResultados: (filtro, paginacion) => {
		let params = {
			filtro: {
				folio: filtro.folio || null,
				id_cat_ticket_status: filtro.empresa || null,
				id_cat_tipo_usuario: filtro.id_cat_tipo_usuario|| null,
				id_cat_cotizacion_estatus: filtro.id_cat_cotizacion_estatus|| null,
				celular: filtro.celular|| null,
				correo_electronico: filtro.correo_electronico|| null,
				activo: (Number(filtro.activo) === 1 || Number(filtro.activo) === 0) ? Number(filtro.activo) : null,
			},
			paginacion: {
				total: paginacion.total || null,
				page: paginacion.page || null,
				limit: paginacion.limit || null
			}
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Resultados_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarArchivo: (form) => {
		let params = {
			id_cotizacion: form.id_cotizacion,
			folio: form.folio,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Datos_Archivo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListarComprobantePago: (form) => {
		let params = {
			id_cotizacion: form.id_cotizacion,
			folio: form.folio,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Datos_Comprobante_Pago', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let fecha_solicitud = form.fecha_solicitud ? DateFormat.FormatSql(form.fecha_solicitud):'';
		
		let params = {
			id_cotizacion: form.id_cotizacion,
			id_usuario : form.id_usuario,
			empresa : form.empresa,
			contacto_empresa : form.contacto_empresa,
			telefono : form.telefono,
			celular : form.celular,
			puesto : form.puesto,
			nombre : form.nombre,
			apellido_paterno : form.apellido_paterno,
			apellido_materno : form.apellido_materno,
			id_cat_estado_empresa : form.id_cat_estado_empresa,
			id_cat_municipio_empresa : form.id_cat_municipio_empresa,
			correo_electronico : form.correo_electronico,
			cotizaciones: form.lista_cotizaciones,
			
			activo : 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarEmpresa: (form) => {
		let fecha_solicitud = form.fecha_solicitud ? DateFormat.FormatSql(form.fecha_solicitud):'';
		
		let params = {
			id_cotizacion: form.id_cotizacion,
			id_usuario : form.id_usuario,
			empresa : form.empresa,
			contacto_empresa : form.contacto_empresa,
			telefono : form.telefono,
			celular : form.celular,
			puesto : form.puesto,
			nombre : form.nombre,
			apellido_paterno : form.apellido_paterno,
			apellido_materno : form.apellido_materno,
			id_cat_estado_empresa : form.id_cat_estado_empresa,
			id_cat_municipio_empresa : form.id_cat_municipio_empresa,
			correo_electronico : form.correo_electronico,
			cotizaciones: form.lista_cotizaciones,
			
			activo : 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Guardar_Empresa', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarArchivo: (form) => {
		let params = {
			id_cotizacion: form.id_cotizacion,
			archivos: form.archivo,
			activo : 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Guardar_Archivo', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	AgregarComprobantePago: (form) => {
		let params = {
			id_cotizacion: form.id_cotizacion,
			archivos: form.archivo,
			activo : 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Guardar_Comprobante_Pago', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let fecha_solicitud = form.fecha_solicitud ? DateFormat.FormatSql(form.fecha_solicitud):'';
		
		let params = {
			id_cotizacion: form.id_cotizacion,
			empresa : form.empresa,
			contacto_empresa : form.contacto_empresa,
			telefono : form.telefono,
			celular : form.celular,
			correo_electronico : form.correo_electronico,
			cotizaciones: form.lista_cotizaciones,
			/*id_evaluacion : form.id_evaluacion,
			posicion : form.posicion,
			cantidad : form.cantidad,
			id_cat_estado : form.id_cat_estado,
			id_cat_municipio : form.id_cat_municipio,
			fecha_solicitud : fecha_solicitud,*/
			activo : 1,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_orientacion) => {let params = {
			id_orientacion: id_orientacion
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	SendEmail: (form) => {let params = {
			id_cotizacion_archivo: form.selected_radio,
			correo_electronico: form.correo_electronico,
			correo_electronico_copia: form.correo_electronico_copia
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Enviar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Chat: (form) => {let params = {
			id_cotizacion: form.id_cotizacion,
			sms: form.sms,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Chat_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ChatListar: (id_cotizacion) => {let params = {
			id_cotizacion: id_cotizacion,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Chat_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ChatVisto: (id_cotizacion) => {let params = {
			id_cotizacion: id_cotizacion,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Chat_Modificar_Visto', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ChatGuardar: (form) => {let params = {
			id_cotizacion: form.id_cotizacion,
			sms: form.sms,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Chat_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Estatus: (form) => {let params = {
			id_cotizacion: form.id_cotizacion,
			id_cat_cotizacion_estatus: form.id_cat_cotizacion_estatus,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Estatus', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	CandidatosGuardar: (form) => {let params = {
			id_cotizacion: form.id_cotizacion,
			candidatos: form.lista_candidatos,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Candidatos_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	CandidatosModificar: (form) => {let params = {
			id_cotizacion: form.id_cotizacion,
			candidatos: form.lista_candidatos,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Cotizacion_Candidatos_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
