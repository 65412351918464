import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../Include/DraggableModal/DraggableModal";
import {FieldsJs, isEnter} from "../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {SexoService} from "../../../services/_Cat/SexoService/SexoService";
import {BotonAccionAbrirModal} from "../../Include/MiniComponents/GlobalComponent";
import {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import LogoPageFull from "../../Include/MiniComponents/LogoPageFull";
import {AccountCircle, Security} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {LogInService} from "../../../services/_Full/LogInService/LogInService";
import {ReactLocalStorageService} from "../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {Redirect} from "react-router-dom";

class ModalLogin extends Component {
	
	state = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		const Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_cat_sexo: (props.item || {}).id_cat_sexo,
			sexo: '',
			abreviatura: '',
			activo: true,
			
			username: Usr.username || '',
			password: '',
			toHomeDashboard: false
		};
		
		this.login = this.login.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	
	
	login() {
		console.log("LOGIN::: ", this.state);
		LogInService.LogIn(this.state).then(response => {
			this.setState({
				toHomeDashboard: true
			});
		}).catch(error => {
			this.showSnackBars('error', error.mensaje);
		});
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		if (this.state.id_cat_sexo > 0) {
			SexoService.Modificar(this.state).then((response) => {
				this.close();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			SexoService.Agregar(this.state).then((response) => {
				this.close();
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_cat_sexo: '',
			sexo: '',
			abreviatura: '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_sexo: item.id_cat_sexo || '',
			sexo: item.sexo || '',
			abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_sexo: item.id_cat_sexo || '',
			sexo: item.sexo || '',
			abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		if (this.state.toHomeDashboard === true) {
			return <Redirect to='/home'/>
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					{/*<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'sexo'}
						/>
					</DialogTitle>*/}
					
					<DialogContent>
						
						<div className='LogIn bg-img-cover-x-center-y-center'>
							<section className="bg-white-transparent b-r-5 w-100-20-px">
								
								<div className="padding-20">
									
									<div className={'logologin'}>
										<LogoPageFull/>
									</div>
									
									<div className="margin-20-B">
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AccountCircle className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="username"
												           className={'bg-transparent'}
												           onChange={this.handleChange}
												           label="Correo o Usuario" autoComplete={'off'}
												           value={this.state.username}
												           onKeyPress={(e) => {
													           if (isEnter(e)) {
														           this.login();
													           }
												           }}
												/>
											</Grid>
										</Grid>
									</div>
									
									<div className="margin-10-B">
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Security className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'password'} fullWidth name="password"
												           className={'bg-transparent'}
												           onChange={this.handleChange} label="Contraseña"
												           autoComplete={'off'}
												           value={this.state.password}
												           onKeyPress={(e) => {
													           if (isEnter(e)) {
														           this.login();
													           }
												           }}
												/>
											</Grid>
										</Grid>
									</div>
									
									<div className="padding-20-T padding-20-B" align={'right'}>
										<Button variant="contained" color="default" onClick={this.close} className={'btn-default text-transform-none margin-15-R'}>
											Cancelar
										</Button>
										
										<Button variant="contained" color="primary" onClick={this.login} className={'btn-default-primary text-transform-none'}>
											Iniciar sesión
										</Button>
									</div>
									
									{/*<div className={'w-100 margin-10-T margin-10-B'}>
												<Link to={'/recuperar'} className={'cursor-pointer'} style={{color: 'black'}}>
													¿Olvidaste tu contraseña?
												</Link>
											</div>
											
											<div className={'w-100 margin-10-T margin-10-B'}>
												<Link to={'/registro'} className={'cursor-pointer'} style={{color: 'black'}}>Completar registro</Link>
											</div>*/}
								
								</div>
							
							</section>
						</div>
					
					</DialogContent>
					
					<Divider/>
					
					{/*<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_sexo}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>*/}
				
				</Dialog>
			
			</div>
		);
	}
}

ModalLogin.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalLogin;
