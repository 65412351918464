import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const EmpresaService = {
	Listar: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Datos', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Ver: () => {
		let params = {};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Datos_Xid', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			id_empresa: form.id_empresa || '',
			empresa: form.empresa || '',
			estado: form.estado || '',
			id_cat_municipio: form.id_cat_municipio || '',
			id_cat_municipio_empresa: form.id_cat_municipio_empresa || '',
			rfc: form.rfc || '',
			id_cat_estado_empresa: form.id_cat_estado_empresa || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			municipio: form.municipio || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Modificar: (form) => {
		let params = {
			id_empresa: form.id_empresa || '',
			empresa: form.empresa || '',
			estado: form.estado || '',
			id_cat_municipio: form.id_cat_municipio || '',
			id_cat_municipio_empresa: form.id_cat_municipio_empresa || '',
			rfc: form.rfc || '',
			id_cat_estado_empresa: form.id_cat_estado_empresa || '',
			correo_electronico: form.correo_electronico || '',
			telefono: form.telefono || '',
			municipio: form.municipio || '',
			activo: form.activo ? 1 : 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Modificar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Eliminar: (id_empresa) => {let params = {
			id_empresa: id_empresa
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Empresa_Eliminar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	}
};
