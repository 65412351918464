import React, {Component, Fragment} from 'react';

import Header from '../../Include/Header/Header';
import Footer from '../../Include/Footer/Footer';

import Solicitud from './Solicitud';
import './Solicitud.css';

import {VerificarTokenAccess} from '../../../services/_Sis/VerificarTokenAccess/VerificarTokenAccess';
import {ReactLocalStorageService} from "../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import TokenNoValido from "../../../componets/Include/TokenNoValido/TokenNoValido";
import {GetTipoMenu} from "../../../settings/General/General";
import login_background from "../../../assets/img/fondo-00.jpg";

class SolicitudIndex extends Component {
	
	state = {
		access: false
	};
	
	constructor(props) {
		
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			access: null
		};
		
		VerificarTokenAccess.Active().then((response) => {
			this.setState({
				access: 1
			});
		}).catch((error) => {
			this.setState({
				access: 0
			});
		});
	}
	
	render() {
		const bg_fondo = {
			backgroundImage: `url(${login_background})`,
			backgroundRepeat: `no-repeat`,
			backgroundPosition: `center`,
			backgroundSize: `cover`,
		};
		
		return (
			<div className='SolicitudIndex'>
				
				<Header/>
				
				<div className={`Container ${GetTipoMenu()}`} style={bg_fondo}>
					
					{this.state.access === 1 ? (
						
						<Fragment>
							
							<Solicitud/>
							
							<Footer/>
						
						</Fragment>
					
					) : ''}
					
					{this.state.access === 0 ? (
						
						<Fragment>
							
							<TokenNoValido/>
						
						</Fragment>
					
					) : ''}
				
				</div>
			
			</div>
		);
	}
}

export default SolicitudIndex;
