import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {SexoService} from "../../../services/_Cat/SexoService/SexoService";
import {BotonAccionAbrirModal, TituloHeaderModal} from "../../Include/MiniComponents/GlobalComponent";
import LogoPageFull from "../../Include/MiniComponents/LogoPageFull";
import {AlternateEmailOutlined, HdrStrongOutlined, HdrWeakOutlined, Person, PhoneIphoneOutlined, PhoneOutlined, TurnedInNotOutlined, Work, DeviceHubOutlined,
VpnKeyOutlined} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {LogInService} from "../../../services/_Full/LogInService/LogInService";
import {ReactLocalStorageService} from "../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {Redirect} from "react-router-dom";
import {CatService} from "../../../services/_Cat/CatService/CatService";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {UsuariosService} from "../../../services/_Sis/UsuariosService/UsuariosService";
import {RegistroService} from "../../../services/_Full/RegistroService/RegistroService";
import {PopupService} from "../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../settings/Config/Config";
import ModalRegisterWelcome from "./ModalRegisterWelcome";

class ModalRegister extends Component {
	
	state = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		const Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false,
				open_welcome: false
			},
			
			tipo: props.tipo,
			
			id_usuario: (props.item || {}).id_usuario,
			cat_estado: (props.cat_estado || []),
			cat_municipio: [],
			cat_sexo: [],
			nombre_comercial: '',
			razon_social: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			id_cat_sexo: '',
			puesto: '',
			celular: '',
			telefono: '',
			correo_electronico: '',
			password: '',
			password_confirmar: '',
			
			activo: true,
			username: Usr.username || '',
			toHomeDashboard: false
		};
		
		this.register = this.register.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	
	
	register() {
		console.log("LOGIN::: ", this.state);
		let msg = `Ya eres un usuario registrado`;
		RegistroService.Agregar(this.state).then(response => {
			// this.closeModal();
			this.openModalWelcome();
			this.showSnackBars('success', 'Usuario registrado con éxito.');
			
		}).catch(error => {
			this.props.showSnackBars('error', error.mensaje);
		});
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModalWelcome = () => {
		this.setState({
			modal: {
				open: false,
				open_welcome: true
			}
		});
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		this.ListaSexo();
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	ListaSexo = () => {
		CatService.ListSexo().then((response) => {
			this.setState({
				cat_sexo: response.data
			});
		}).catch((error) => {
			this.setState({
				cat_sexo: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	add = () => {
		console.log(this.props);
		this.setState({
			id_usuario: (this.props.item || {}).id_usuario,
			cat_estado: (this.props.cat_estado || []),
			cat_municipio: [],
			cat_sexo: [],
			nombre_comercial: '',
			razon_social: '',
			id_cat_estado: '',
			id_cat_municipio: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			id_cat_sexo: '',
			puesto: '',
			celular: '',
			telefono: '',
			correo_electronico: '',
			password: '',
			password_confirmar: '',
			
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_sexo: item.id_cat_sexo || '',
			sexo: item.sexo || '',
			abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cat_sexo: item.id_cat_sexo || '',
			sexo: item.sexo || '',
			abreviatura: item.abreviatura || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	
	ListMunicipio = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		
		for (let i = 0; i < this.state.cat_estado.length; i++) {
			if (Number(this.state.cat_estado[i].id_cat_estado) === Number(id_cat_estado)) {
				this.setState({
					estado: this.state.cat_estado[i].estado
				});
			}
		}
		
		CatService.ListMunicipio(id_cat_estado).then((response) => {
			this.setState({
				cat_municipio: response.data
			});
		});
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		if (this.state.toHomeDashboard === true) {
			return <Redirect to='/home'/>
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_sexo}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				{ this.state.modal.open_welcome ? (
					<ModalRegisterWelcome item={this.state} open={true}/>
				): null}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					{/*<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'sexo'}
						/>
					</DialogTitle>*/}
					
					<DialogContent>
						
						<div className='LogIn bg-img-cover-x-center-y-center'>
							<section className="bg-white-transparent b-r-5 w-100-20-px">
								
								<div className="padding-20">
									
									<div className={'logologin'}>
										<LogoPageFull/>
									</div>
									
									<div className="margin-20-B">
										<Grid container spacing={2}>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<label>
													<TituloHeaderModal
														tipo={''}
														titulo={'Información de la empresa'}
													/>
												</label>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<TurnedInNotOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="nombre_comercial"
														           onChange={this.handleChange}
														           label="Nombre comercial" autoComplete={'off'}
														           value={this.state.nombre_comercial}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<TurnedInNotOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="razon_social"
														           onChange={this.handleChange}
														           label="Razón social" autoComplete={'off'}
														           value={this.state.razon_social}
														           required
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<HdrWeakOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<FormControl fullWidth required>
															<InputLabel>Estado</InputLabel>
															<Select
																native
																value={this.state.id_cat_estado}
																onChange={(e) => {
																	this.handleChange(e);
																	this.ListMunicipio(e);
																}}
																name="id_cat_estado"
																input={<Input/>}
															>
																<option value=""/>
																{this.state.cat_estado.map(option => (
																	<option key={option.id_cat_estado}
																	        value={option.id_cat_estado}>
																		{option.estado}
																	</option>
																))}
															
															</Select>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
											
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<HdrStrongOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														
														<FormControl fullWidth required>
															<InputLabel>Municipio</InputLabel>
															<Select
																native
																value={this.state.id_cat_municipio}
																onChange={this.handleChange}
																name="id_cat_municipio"
																input={<Input/>}
															>
																<option value=""/>
																{this.state.cat_municipio.map(option => (
																	<option key={option.id_cat_municipio}
																	        value={option.id_cat_municipio}>
																		{option.municipio}
																	</option>
																))}
															
															</Select>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<Divider style={{marginTop: "10px", marginBottom: "10px"}}/>
											</Grid>
											
											<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
												<label>
													<TituloHeaderModal
														tipo={''}
														titulo={'Información del contacto'}
													/>
												</label>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<Person className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="nombre"
														           onChange={this.handleChange}
														           label="Nombre" autoComplete={'off'}
														           value={this.state.nombre}
														           required
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<Person className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="apellido_paterno"
														           onChange={this.handleChange}
														           label="Apellido Paterno" autoComplete={'off'}
														           value={this.state.apellido_paterno}
														           required
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<Person className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="apellido_materno"
														           onChange={this.handleChange}
														           label="Apellido Materno" autoComplete={'off'}
														           value={this.state.apellido_materno}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<DeviceHubOutlined className={'w-100 text-gray'}/>
													</Grid>
													
													<Grid item className={'w-100-30-px'}>
														<TextField
															select
															fullWidth
															onChange={this.handleChange}
															SelectProps={{
																native: true,
																MenuProps: {},
															}}
															
															name="id_cat_sexo"
															label="Sexo"
															value={this.state.id_cat_sexo}
															required
														>
															<option key={0} value={''}>&nbsp;</option>
															{this.state.cat_sexo.map(option => (
																<option key={option.id_cat_sexo}
																        value={option.id_cat_sexo}>
																	{option.sexo}
																</option>
															))}
														</TextField>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<Work className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="puesto"
														           onChange={this.handleChange}
														           label="Puesto" autoComplete={'off'}
														           value={this.state.puesto}
														           required
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<PhoneIphoneOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="celular"
														           onChange={this.handleChange}
														           inputProps={{maxLength: 10}}
														           onKeyPress={EnteroSolo}
														           label="Celular" autoComplete={'off'}
														           value={this.state.celular}
														           required
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<PhoneIphoneOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="telefono"
														           onChange={this.handleChange}
														           inputProps={{maxLength: 10}}
														           onKeyPress={EnteroSolo}
														           label="Teléfono" autoComplete={'off'}
														           value={this.state.telefono}
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<AlternateEmailOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'text'} fullWidth name="correo_electronico"
														           onChange={this.handleChange}
														           label="Correo electrónico" autoComplete={'off'}
														           value={this.state.correo_electronico}
														           required
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<VpnKeyOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'password'} fullWidth name="password"
														           onChange={this.handleChange}
														           label="Contraseña" autoComplete={'off'}
														           value={this.state.password}
														           required
														/>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<VpnKeyOutlined className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<TextField type={'password'} fullWidth name="password_confirmar"
														           onChange={this.handleChange}
														           label="Confirmar contraseña" autoComplete={'off'}
														           value={this.state.password_confirmar}
														           required
														/>
													</Grid>
												</Grid>
											</Grid>
										
										</Grid>
									</div>
									
									
									{/*<div className={'w-100 margin-10-T margin-10-B'}>
												<Link to={'/recuperar'} className={'cursor-pointer'} style={{color: 'black'}}>
													¿Olvidaste tu contraseña?
												</Link>
											</div>
											
											<div className={'w-100 margin-10-T margin-10-B'}>
												<Link to={'/registro'} className={'cursor-pointer'} style={{color: 'black'}}>Completar registro</Link>
											</div>*/}
								
								</div>
							
							</section>
						</div>
					
					</DialogContent>
					
					<DialogActions>
						
						<div align={'right'}>
							<Button variant="contained" color="default" onClick={this.close} className={'btn-default text-transform-none margin-15-R'}>
								Cancelar
							</Button>
							
							<Button variant="contained" color="primary" onClick={this.register} className={'btn-default-primary text-transform-none'}>
								Crear Cuenta
							</Button>
						</div>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalRegister.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	cat_estado: PropTypes.array.isRequired,
};

export default ModalRegister;
