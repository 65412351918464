import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogActions from '@material-ui/core/DialogActions/index';

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../Include/DraggableModal/DraggableModal";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import {ReactLocalStorageService} from "../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import img_welcome from "../../../assets/img/icon-catalogo/welcome.svg";
import LogoPageFull from "../../Include/MiniComponents/LogoPageFull";
import Grid from "@material-ui/core/Grid";

class ModalCotizacionWelcome extends Component {
	
	state = {};
	showSnackBars = this.props.showSnackBars;
	
	constructor(props) {
		super(props);
		
		const Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: props.open || false
			},
			
			folio: (props.item || '').folio,
			correo_electronico: (props.item || '').correo
		};
	}
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		this.setState({
			modal: {
				open: true
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	render() {
		
		return (
			<div>
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title" className={'register-welcome'}
				>
					
					{/*<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'sexo'}
						/>
					</DialogTitle>*/}
					
					<DialogContent>
						
						<div className='LogIn bg-img-cover-x-center-y-center'>
							<section className="bg-white-transparent b-r-5 w-100">
								<div className={'logologin'}>
									<LogoPageFull/>
								</div>
								
								<div className={'text-left'}>
									<h3 style={{color: '#3f51b5'}}>Tu solicitud ha sido registrada satisfactoriamente</h3>
								</div>
								
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<div className={'text-left'}>
											<h4>El folio asignado a tu solicitud es: <strong style={{color: '#3f51b5'}}>#{this.state.folio}</strong></h4>
											<h4>Vamos a analizar la información que nos proporcionaste, para compartirte una propuesta.</h4>
											<p className={'margin-50-T'}>Se te enviará la cotización adjunta, al correo: <strong style={{color: '#3f51b5'}}>{this.state.correo_electronico}</strong></p>
											<p className={'margin-30-T'}>Gracias por cotizar con nosotros.</p>
										</div>
									</Grid>
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
										<div className={'text-center'}>
											<img src={img_welcome} alt={'Logo'} width={'100%'} height={'300px'} className={'margin-15-L'}/>
										</div>
									</Grid>
								
								</Grid>
							</section>
						</div>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<div className="" align={'right'}>
							<Button variant="contained" color="primary" onClick={this.close} className={'btn-default-primary text-transform-none'}>
								Aceptar
							</Button>
						</div>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCotizacionWelcome.propTypes = {
	item: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
};

export default ModalCotizacionWelcome;
