import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import Button from "@material-ui/core/Button";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {CotizacionService} from "../../../../services/_Sis/CotizacionService/CotizacionService";
import Typography from "@material-ui/core/Typography";
import SVGAceptar from "../../../../assets/img/icons/aprobar.svg";
import SVGRechazar from "../../../../assets/img/icons/rechazar.svg";
import SVGPropuesta from "../../../../assets/img/icons/nueva_propuesta.svg";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import TextField from "@material-ui/core/TextField";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import moment from "moment";

class ModalOptionsCotizacion extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_cotizacion: (props.item || {}).id_cotizacion,
			correo_electronico: (props.item || {}).correo_electronico,
			cotizaciones: (props.item || []).cotizaciones,
			orientacion: '',
			selected: '',
			motivo: '',
			activo: true,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	add = () => {
		console.log(this.props);
		const {item} = this.props;
		let selected = '';
		if (item.cotizaciones.length > 0) {
			selected = item.cotizaciones[0].id_cotizacion_archivo.toString();
		}
		this.setState({
			cotizaciones: item.cotizaciones || [],
			selected_radio: selected,
			correo_electronico: item.correo_electronico || '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_orientacion: item.id_orientacion || '',
			orientacion: item.orientacion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_orientacion: item.id_orientacion || '',
			orientacion: item.orientacion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	opcionSelected = (opt) => {
		this.setState({
			selected: opt
		});
	};
	
	save = () => {
		try {
			if (this.state.selected === '') {
				throw Object({
					status: false, mensaje: 'Debes seleccionar una opción.'
				})
			}
			
			let msg = '';
			switch (Number(this.state.selected)) {
				case 1:
					msg = `¿Deseas aceptar la cotización?`;
					this.setState({id_cat_cotizacion_estatus: 4});
					break;
				case 2:
					msg = `¿Deseas solicitar una nueva propuesta?`;
					this.setState({id_cat_cotizacion_estatus: 5});
					break;
				case 3:
					msg = `¿Deseas eliminar la cotización?`;
					this.setState({id_cat_cotizacion_estatus: 6});
					break;
				default:
					break;
			}
			
			if (FieldsJs.inArray([2 , 3], this.state.selected) ) {
				if (!this.state.motivo) {
					throw Object({
						status: false, mensaje: 'El campo motivo es requerido.'
					})
				}
			}
			
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
				if (r.button === 'Aceptar') {
					CotizacionService.Estatus(this.state).then((response) => {
						this.close();
						this.props.showSnackBars('success', response.mensaje);
						this.props.RefreshList();
					}).catch((error) => {
						this.props.showSnackBars('error', error.mensaje);
					});
				}
			});
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		const estilo = {
			height: "150px",
			width: "150px",
			cursor: "pointer",
			marginBottom: "15px",
			marginTop: "15px",
		};
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_orientacion}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle align={'center'}>
						Opciones
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} align={'center'}>
								<div className={(this.state.selected === 1 ? 'hover-option-active' : 'hover-option') + ' cursor-pointer borde-punteado-lightgray-2'} style={{borderRadius: '8px'}} onClick={() => this.opcionSelected(1)}>
									<img
										src={SVGAceptar}
										alt=""
										style={estilo}
									/>
									<Typography variant="h6" className={'l-h-20 px-16'}>
										Aceptar cotización
									</Typography>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} align={'center'}>
								<div className={(this.state.selected === 2 ? 'hover-option-active' : 'hover-option') + ' cursor-pointer borde-punteado-lightgray-2'} style={{borderRadius: '8px'}} onClick={() => this.opcionSelected(2)}>
									<img
										src={SVGPropuesta}
										alt=""
										style={estilo}
									/>
									<Typography variant="h6" className={'l-h-20 px-16'}>
										Nueva propuesta
									</Typography>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={4} lg={4} xl={4} align={'center'}>
								<div className={(this.state.selected === 3 ? 'hover-option-active' : 'hover-option') + ' cursor-pointer borde-punteado-lightgray-2'} style={{borderRadius: '8px'}} onClick={() => this.opcionSelected(3)}>
									<img
										src={SVGRechazar}
										alt=""
										style={estilo}
									/>
									<Typography variant="h6" className={'l-h-20 px-16'}>
										Rechazar cotización
									</Typography>
								</div>
							</Grid>
							
							{FieldsJs.inArray([2, 3], Number(this.state.selected)) ?
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Typography variant="h6" className={'l-h-20 px-12'}>
										Motivo por el cual desea {this.state.selected === 2 ? 'una nueva propuesta?' : 'rechazar la cotización?'}
									</Typography>
									<TextField
										multiline
										raw={3}
										margin="dense"
										name="motivo"
										label="Motivo"
										type="text"
										fullWidth
										defaultValue={this.state.motivo}
										onChange={this.handleChange}
										disabled={this.state.tipo === 'view'}
									/>
								</Grid> : null}
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cancelar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.save()} color="primary">
									Guardar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			
			</div>
		);
	}
}

ModalOptionsCotizacion.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalOptionsCotizacion;
