import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const RegistroService = {
	VerificarRegistro: (form) => {
		let params = {
			username: form.username,
			correo_electronico: form.username,
			password: form.password,
			codigo: form.codigo,
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Usuario_Registro_Verificar', params, {authentication: false}).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	Agregar: (form) => {
		let params = {
			username: form.username,
			nombre_comercial: form.nombre_comercial,
			razon_social: form.razon_social,
			id_cat_estado: form.id_cat_estado,
			id_cat_municipio: form.id_cat_municipio,
			nombre: form.nombre,
			apellido_paterno: form.apellido_paterno,
			apellido_materno: form.apellido_materno,
			id_cat_sexo: form.id_cat_sexo,
			puesto: form.puesto,
			celular: form.celular,
			telefono: form.telefono,
			correo_electronico: form.correo_electronico,
			password: form.password,
			password_confirmar: form.password_confirmar,
			activo: 1,
			sendmail: 1,
			isjefe: 0
		};
		return new Promise((resolve, reject) => {
			HttpRequest.post('_Usuario_Registro_Landing_Guardar', params).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
