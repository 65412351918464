import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import Button from "@material-ui/core/Button";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {CotizacionService} from "../../../../services/_Sis/CotizacionService/CotizacionService";

class ModalSendFactura extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_cotizacion: (props.item || {}).id_cotizacion,
			correo_electronico: (props.item || {}).correo_electronico,
			facturas: (props.item || []).facturas,
			orientacion: '',
			selected_radio: '',
			activo: true,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		CotizacionService.SendEmail(this.state).then((response) => {
			this.close();
			this.props.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	add = () => {
		console.log(this.props);
		const {item} = this.props;
		let selected = '';
		if (item.facturas.length > 0) {
			selected = item.facturas[0].id_cotizacion_archivo.toString();
		}
		this.setState({
			facturas: item.facturas || [],
			selected_radio: selected,
			correo_electronico: item.correo_electronico || '',
			activo: true,
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_orientacion: item.id_orientacion || '',
			orientacion: item.orientacion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_orientacion: item.id_orientacion || '',
			orientacion: item.orientacion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_orientacion}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						Enviar Factura
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							{this.state.facturas.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									
									<FormControl component="fieldset">
										<FormLabel component="legend">Selecciona la cotización:</FormLabel>
										<RadioGroup row aria-label="position" name="selected_radio" defaultValue="end" value={this.state.selected_radio} onChange={this.handleChange}>
											{this.state.facturas.map((item, key) => (
												<FormControlLabel key={key} value={item.id_cotizacion_archivo.toString()} control={<Radio/>} label={item.created_at}/>
											))}
										
										</RadioGroup>
									</FormControl>
									
									{/*<TableRow>
										<TableCell style={{width: "50%"}} align={'center'} className={'padding-5-L padding-5-R'}>
											<div className={'w-100'}>
												<Tooltip TransitionComponent={Zoom} placement={"top"} title="Descargar Documento">
													<IconButton aria-label="Descargar Documento" onClick={() => this.exportar(item.archivo)}>
														<Description style={{color: '#8E1E32'}}/>
													</IconButton>
												</Tooltip>
											</div>
										</TableCell>
										<TableCell align={'left'}>{item.created_at}</TableCell>
									</TableRow>*/}
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									multiline
									margin="dense"
									name="correo_electronico"
									label="Correo Electrónico"
									type="text"
									fullWidth
									defaultValue={this.state.correo_electronico}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								{this.props.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
							</Button>
							{this.props.tipo !== 'view' ? (
								<Button onClick={{}} color="primary">
									{'Enviar'}
								</Button>
							) : ''}
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalSendFactura.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalSendFactura;
