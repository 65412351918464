import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import Button from "@material-ui/core/Button";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {CotizacionService} from "../../../../services/_Sis/CotizacionService/CotizacionService";
import Typography from "@material-ui/core/Typography";
import SVGAceptar from "../../../../assets/img/icons/aprobar.svg";
import SVGRechazar from "../../../../assets/img/icons/rechazar.svg";
import SVGPropuesta from "../../../../assets/img/icons/nueva_propuesta.svg";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import {Send} from "@material-ui/icons";

class ModalOptionsCotizacion extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_cotizacion: (props.item || {}).id_cotizacion,
			correo_electronico: (props.item || {}).correo_electronico,
			orientacion: '',
			selected_radio: '',
			mensajes: [],
			activo: true,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		CotizacionService.ChatGuardar(this.state).then((response) => {
			//this.close();
			this.setState({sms: ''});
			this.ListarSms(this.state.id_cotizacion);
			this.props.showSnackBars('success', response.mensaje);
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListarSms = (id_cotizacion) => {
		CotizacionService.ChatListar(id_cotizacion).then((response) => {
			this.setState({
				mensajes: response.data,
			});
		}).catch((error) => {
			this.setState({
				mensajes: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	ListarVisto = (id_cotizacion) => {
		CotizacionService.ChatVisto(id_cotizacion).then((response) => {
			this.props.RefreshList();
			console.log("Sms actualizados")
		}).catch((error) => {
			//this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	add = () => {
		console.log(this.props);
		const {item} = this.props;
		
		this.setState({
			id_cotizacion: item.id_cotizacion || '',
			mensajes: [],
			correo_electronico: item.correo_electronico || '',
			activo: true,
		});
		
		this.ListarVisto(item.id_cotizacion);
		this.ListarSms(item.id_cotizacion);
		
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_cotizacion: item.id_cotizacion || '',
			orientacion: item.orientacion || '',
			activo: (item.activo === 1),
		});
		
		this.ListarSms(item.id_cotizacion);
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_orientacion: item.id_orientacion || '',
			orientacion: item.orientacion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	opcionSelected = (opt) => {
		let msg = '';
		switch (opt) {
			case 1:
				msg = `¿Deseas aceptar la cotización?`;
				break;
			case 2:
				msg = `¿Deseas solicitar una nueva propuesta?`;
				break;
			case 3:
				msg = `¿Deseas eliminar la cotización?`;
				break;
			default: break;
		}
		
		
		PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Aceptar') {
				/*CotizacionService.Eliminar(item.id_cotizacion).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});*/
			}
		});
	};
	
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		const estilo = {
			height: "150px",
			width: "150px",
			cursor: "pointer",
			marginBottom: "15px",
			marginTop: "15px",
		};
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_orientacion}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle align={'center'}>
						Contacto
					</DialogTitle>
					
					<DialogContent>
							<Fragment>
								<article className={'borde-punteado-lightgray-2 card-0 bg-white padding-10 padding-0-T'}>
									
									<Grid container spacing={2} alignItems={"flex-start"} alignContent={"flex-end"} className={'padding-20 bg-image-chat'}>
										
										<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
											<div className={'w-100 vertical-inline'}>
												<div className={'w-100-70-px v-center'}>
													<TextField
														fullWidth
														onChange={this.handleChange}
														type="text"
														margin="none"
														multiline
														helperText=""
														name="sms"
														label="Escribe un mensaje aquí"
														value={this.state.sms}
														//disabled={disabledFieldPromotorIntegracionExpediente}
														InputProps={{
															//endAdornment: <InputAdornment position="end">%</InputAdornment>,
														}}
													/>
												</div>
												<div className={'w-70-px v-end'}>
													<Button variant="contained" color="primary"
													        onClick={() => this.save()}
													        className={'btn-default-primary text-transform-none'}>
														<Send/>
													</Button>
												</div>
											</div>
										</Grid>
										
										<Grid item className={'text-center padding-5'} xs={12} sm={12} md={12} lg={12} xl={12}>
											<div className={'margin-20-T margin-10-B avatar-right'}>
												{this.state.mensajes.map((item, key) => (
													<Fragment key={key}>
														<ListItem>
															<ListItemAvatar>
																<Avatar
																	alt={"perfil"}
																	src={Number(item.id_cat_tipo_usuario) === 2 ? CONFIG.foto_default_logo : item.foto ? CONFIG.src + item.foto : CONFIG.foto_default}
																	className={'margin-0'}
																	style={{
																		height: '35px',
																		width: '35px',
																		marginTop: '2px',
																		textAlign: 'left',
																	}}
																/>
															</ListItemAvatar>
															
															<ListItemText
																style={{
																	fontSize: '12px !important',
																	margin: 0,
																	padding: 0,
																	textAlign: 'left',
																	borderRadius: '5px',
																	backgroundColor: 'rgba(236,236,236,0.59)'
																}}
																primary={<Typography type="body2" style={{fontSize: '11px', margin: 0, padding: '3px 3px 0px 3px'}}>
																	{item.id_cat_tipo_usuario === 2 ? 'Atención al cliente' : item.nombre_completo}</Typography>}
																secondary={
																	<Fragment>
																		<Typography
																			component="span"
																			variant="body2"
																			color="textPrimary"
																			style={{fontSize: '11px', padding: 3}}
																		>
																			Mensaje:
																		</Typography>
																		{" — " + item.sms}
																	</Fragment>
																}
															/>
														</ListItem>
													</Fragment>
												))}
											</div>
										</Grid>
									
									</Grid>
								
								</article>
							
							</Fragment>
					
					</DialogContent>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								{'Cancelar'}
							</Button>
						</Fragment>
					
					</DialogActions>
				</Dialog>
			
			</div>
		);
	}
}

ModalOptionsCotizacion.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalOptionsCotizacion;
