import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ListaTabla from './Includes/ListaTabla';

import {FieldsJs} from "../../../settings/General/General";
import {ReactLocalStorageService} from '../../../settings/ReactLocalStorageService/ReactLocalStorageService';
import VistaVacia from "../../Include/VistaVacia/VistaVacia";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import {
	BotonActualizarLista,
	BotonExportarListaExcel,
	BotonExportarListaPDF,
	CabeceraTituloPdfExcelLista
} from "../../Include/MiniComponents/GlobalComponent";
import Paginacion from "../../Include/Paginacion/Paginacion";
import FiltrosFacturacion from "./Includes/FiltrosFacturacion";
import {FacturacionService} from "../../../services/_Sis/FacturacionService/FacturacionService";


class Facturacion extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor() {
		super();
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		let ids_expandido = ReactLocalStorageService.get('ids_expandido');
		
		
		this.state = {
			listar_cotizaciones: [],
			
			filtro: {
				id_cat_area_solucion: this.Usr.id_cat_tipo_usuario
			},
			
			paginacion: {
				total: null,
				page: 1,
				limit: this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			},
			
			ids_expandido: ids_expandido || []
		};
		
		this.RefreshList();
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	RefreshList = () => {
		this.Listar();
	};
	
	HandleFiltro = (filtro) => {
		this.setState({
			filtro: filtro
		});
	};
	
	DefaultNumPag = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		return Cfg.paginacion_numero_registro ? Number(Cfg.paginacion_numero_registro) : 10;
	};
	
	DefaultRangos = () => {
		let Cfg = ReactLocalStorageService.get('Cfg') || {};
		let rangos = [5, 10, 15, 20];
		if (FieldsJs.Array(Cfg.paginacion_rangos)) {
			rangos = Cfg.paginacion_rangos;
		}
		return rangos;
	};
	
	Listar = () => {
		FacturacionService.Listar(this.state.filtro, this.state.paginacion).then((response) => {
			this.setState({
				listar_cotizaciones: response.data,
				
				paginacion: {
					total: response.paginacion.total,
					page: response.paginacion.page,
					limit: response.paginacion.limit,
					rangos: this.DefaultRangos()
				}
			});
		}).catch((error) => {
			this.setState({
				listar_cotizaciones: []
			});
			this.showSnackBars('error', error.mensaje);
		});
	};
	
	AplicarPaginacion = (data) => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: data.page || paginacion.page || null,
				limit: data.limit || paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	AplicarFiltros = () => {
		let paginacion = this.state.paginacion;
		this.setState({
			paginacion: {
				total: paginacion.total || null,
				page: 1,
				limit: paginacion.limit || this.DefaultNumPag(),
				rangos: this.DefaultRangos()
			}
		});
		setTimeout(() => this.Listar());
	};
	
	handleChange = (e, variable, campo, date, input) => {
		
		if ((date && input) || input) {
			
			this.setState({
				[input]: date
			});
			
		} else {
			
			const {value, name, checked, type} = e.target;
			
			if (variable && campo) {
				
				console.log(value, name, checked, type);
				
				let key = Number(name.split('__')[1]);
				console.log(key);
				
				let arr_temp = this.state[variable];
				
				for (let i = 0; i < arr_temp.length; i++) {
					
					if (key === i) {
						arr_temp[i][campo] = type === 'checkbox' ? checked : value;
					}
				}
				
				this.setState({
					[variable]: arr_temp
				});
				
			} else {
				
				this.setState({
					[name]: type === 'checkbox' ? checked : value
				});
				
			}
			
		}
		
	};
	
	ShowSubTabla = (item, key) => {
		let expandir = !!!item.expandir;
		this.handleChange({
			target: {
				value: expandir,
				name: 'expandir__' + key,
				type: 'text'
			}
		}, 'listar_cotizaciones', 'expandir');
		let ids_temp = this.state.ids_expandido;
		let ids_expandido = [];
		if (expandir) {
			ids_expandido = ids_temp;
			ids_expandido.push({
				id_cotizacion: item.id_cotizacion
			});
		} else {
			for (let i = 0; i < ids_temp.length; i++) {
				if (Number(ids_temp[i].id_cotizacion) !== Number(item.id_cotizacion)) {
					ids_expandido.push({
						id_cotizacion: ids_temp[i].id_cotizacion
					})
				}
			}
			
		}
		ReactLocalStorageService.set('ids_expandido', ids_expandido);
		this.setState({
			ids_expandido: ids_expandido
		});
	};
	
	
	SubTabla = (item, key) => {
		this.ShowSubTabla(item, key);
	};
	
	go = (u) => {
		this.props.history.push('/' + u);
	};
	
	render() {
		
		return (
			<div className='Catalogos ContaineViewComponet'>
				
				<CabeceraTituloPdfExcelLista
					titulo={'Facturación'}
					botonLISTA={
						<Fragment>
							<BotonActualizarLista onClick={() => {
								this.Listar();
							}}/>
						</Fragment>
					}
				/>
				
				<FiltrosFacturacion
					AplicarFiltros={this.AplicarFiltros}
					HandleFiltro={this.HandleFiltro}
					showSnackBars={this.showSnackBars}
				/>
				
				<div className={'form margin-20-T margin-15-B'}>
					
					{this.state.listar_cotizaciones.length > 0 ? (
							<Fragment>
								<ListaTabla
									lista={this.state.listar_cotizaciones}
									RefreshList={this.RefreshList}
									showSnackBars={this.showSnackBars}
									SubTabla={this.SubTabla}
								/>
								<Paginacion
									total={this.state.paginacion.total}
									page={this.state.paginacion.page}
									limit={this.state.paginacion.limit}
									rangos={this.state.paginacion.rangos}
									onClick={(data) => this.AplicarPaginacion(data)}
								/>
							</Fragment>
						) : (
						<VistaVacia
							numero={0}
							mensaje={'No se encontraron datos.'}
						/>
					)}
				
				</div>
				
				{/*<ModalCotizaciones
					tipo={'add'}
					item={{}}
					RefreshList={this.RefreshList}
					showSnackBars={this.showSnackBars}
				/>*/}
			
			</div>
		);
	}
}

Facturacion.propTypes = {
};

export default IntegrationNotistack(Facturacion);
