import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import './Solicitud.css';
import logo_svg from '../../../assets/img/acresco_talento_gray.svg';

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../settings/IntegrationNotistack/IntegrationNotistack";
import NuevaSolicitud from "./NuevaSolicitud";
import {ReactLocalStorageService} from "../../../settings/ReactLocalStorageService/ReactLocalStorageService";

// import Button from "@material-ui/core/Button";

class Solicitud extends Component {
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		
	}
	
	showSnackBars = (type, message) => {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	render() {
		
		const bg_logo_svg = {
			backgroundImage: `url(${logo_svg})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundAttachment: 'fixed',
			backgroundSize: '300px auto',
			opacity: '1',
		};
		
		return (
			<div className='Solicitud ContaineViewComponet'>
				<div className={'w-auto content-image vertical-inline'}>
					<div className={'w-100 v-center'}>
						
						<Fragment>
							<div style={{padding: '0px 40px 0px 40px' }}>
								<NuevaSolicitud/>
							</div>
						</Fragment>
						{/*
						<Button onClick={() => this.showSnackBars('info', 'Bienvenidos a Acresco Integraciones!!')}>
							Show
						</Button>
						*/}
					</div>
				</div>
			</div>
		);
	}
}

Solicitud.propTypes = {
};

export default IntegrationNotistack(Solicitud);
