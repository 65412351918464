import React, {Component, Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';

import App from './componets/App';
import LogInIndex from './componets/PageFull/LogIn';
import RegistroIndex from './componets/PageFull/Registro';
import RegistroDatosPersonalesIndex from './componets/PageFull/RegistroDatosPersonales';
import RecuperarIndex from './componets/PageFull/Recuperar';
import VerificarIndex from './componets/PageFull/Verificar';
import CambiarContrasenaIndex from './componets/PageFull/CambiarContrasena';
import CatalogosIndex from './componets/PageContent/Catalogos';
import SexoIndex from './componets/PageContent/Cat/Sexo';
import TipoUsuarioIndex from './componets/PageContent/Cat/TipoUsuario';
import MenuSubMenuIndex from './componets/PageContent/Cat/MenuSubMenu';
import RolesPermisosIndex from './componets/PageContent/Cat/RolesPermisos';
import UsuariosIndex from './componets/PageContent/Usuarios';
import HomeIndex from './componets/PageContent/Home';
import PerfilIndex from './componets/PageContent/Perfil';
import CotizacionesIndex from './componets/PageContent/Cotizaciones';
import CotizadorIndex from './componets/PageContent/Cotizador';
import LandingPageIndex from './componets/LandingPage/LandingPage';
import SolicitudIndex from './componets/PageContent/Solicitud';
import FacturacionIndex from './componets/PageContent/Facturacion';
import ResultadosIndex from './componets/PageContent/Resultados';
import EmpresaIndex from './componets/PageContent/Empresa';

import Page404Index from './componets/PageFull/Page404';

import EventListenerClickDom from './componets/Include/EventListenerClickDom/EventListenerClickDom';
import NuevaSolicitud from "./componets/PageContent/Solicitud/NuevaSolicitud";
import Facturacion from "./componets/PageContent/Facturacion/Facturacion";

class AppRoutes extends Component {
	
	render() {
		return (
			<Fragment>
				<EventListenerClickDom/>
				<App>
					<Switch>
						<Route exact path="/landing" component={LandingPageIndex}/>
						<Route exact path="/login" component={LogInIndex}/>
						<Route exact path="/registro" component={RegistroIndex}/>
						<Route exact path="/registrodatospersonales" component={RegistroDatosPersonalesIndex}/>
						<Route exact path="/recuperar" component={RecuperarIndex}/>
						<Route exact path="/verificar" component={VerificarIndex}/>
						<Route exact path="/cambiarcontrasena" component={CambiarContrasenaIndex}/>
						<Route exact path="/catalogo" component={CatalogosIndex}/>
						<Route exact path="/genero" component={SexoIndex}/>
						<Route exact path="/tipousuario" component={TipoUsuarioIndex}/>
						<Route exact path="/menus" component={MenuSubMenuIndex}/>
						<Route exact path="/rolespermisos" component={RolesPermisosIndex}/>
						<Route exact path="/usuario" component={UsuariosIndex}/>
						<Route exact path="/home" component={HomeIndex}/>
						<Route exact path="/perfil" component={PerfilIndex}/>

						<Route exact path="/cotizaciones" component={CotizacionesIndex}/>
						<Route exact path="/cotizador" component={CotizadorIndex}/>
						<Route exact path="/solicitud" component={SolicitudIndex}/>
						<Route exact path="/facturacion" component={FacturacionIndex}/>
						<Route exact path="/resultados" component={ResultadosIndex}/>
						<Route exact path="/empresas" component={EmpresaIndex}/>
						
						<Route exact path="/" component={LandingPageIndex}/>
						<Route component={Page404Index}/>
					</Switch>
				</App>
			</Fragment>
		);
	}
}

export default AppRoutes;
