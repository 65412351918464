import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {DatePicker, TimePicker} from "@material-ui/pickers";
import {BotonAccionAbrirModal} from "../../../Include/MiniComponents/GlobalComponent";
import Button from "@material-ui/core/Button";
import FormLabel from '@material-ui/core/FormLabel';
import moment from "moment";
import {CotizacionService} from "../../../../services/_Sis/CotizacionService/CotizacionService";
import {AddOutlined, AlternateEmailOutlined, CancelOutlined, CheckOutlined, Comment, DeleteOutlined, EditOutlined, FiberManualRecord, HdrStrongOutlined, Person, PhoneIphoneOutlined, QueryBuilder, SaveOutlined, Today, Work} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import VistaVacia from "../../../Include/VistaVacia/VistaVacia";
import carrito_de_compra_3 from '../../../../assets/img/icons/chat.svg'
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {CatService} from "../../../../services/_Cat/CatService/CatService";


class ModalCandidatos extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_cotizacion: (props.item || {}).id_cotizacion,
			lista_candidatos: (props.item || []).candidatos,
			
			orientacion: '',
			selected_radio: '',
			id_cotizacion_candidato: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			puesto: '',
			telefono: '',
			celular: '',
			mensaje: '',
			correo_electronico: '',
			id_evaluacion: '',
			evaluacion: '',
			fecha_disponible: null,
			hora_disponible: null,
			fecha_entrevista: null,
			hora_entrevista: null,
			
			cat_evaluacion_estatus: [],
			activo: true,
			checkPS: false,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		if (this.is_admin()) {
			CotizacionService.CandidatosModificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			CotizacionService.CandidatosGuardar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	evaluacionEstatus = () => {
		CatService.ListEvaluacionEstatus(this.state).then((response) => {
			this.setState({
				cat_evaluacion_estatus: response.data
			});
		}).catch((error) => {
			this.setState({
				cat_evaluacion_estatus: []
			});
			this.props.showSnackBars('error', error.mensaje);
		});
	};
	
	add = () => {
		console.log(this.props);
		const {item} = this.props;
		
		this.setState({
			lista_candidatos: item.candidatos || [],
			
			id_cotizacion_candidato: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			puesto: '',
			telefono: '',
			celular: '',
			mensaje: '',
			correo_electronico: '',
			id_evaluacion: '',
			evaluacion: '',
			fecha_disponible: null,
			hora_disponible: null,
			activo: true,
		});
		
		this.evaluacionEstatus();
		
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_orientacion: item.id_orientacion || '',
			orientacion: item.orientacion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.setState({
			id_orientacion: item.id_orientacion || '',
			orientacion: item.orientacion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	is_admin = () => {
		return FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario);
	};
	
	
	is_edited_candidato = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	edit_candidato = (value, key) => {
		try {
			let evaluacion_estatus = '';
			for (let j = 0; j < this.state.cat_evaluacion_estatus.length; j++) {
				if (Number(value.id_cat_evaluacion_estatus) === Number(this.state.cat_evaluacion_estatus[j].id_cat_evaluacion_estatus)) {
					evaluacion_estatus = this.state.cat_evaluacion_estatus[j].evaluacion_estatus;
				}
			}
			
			this.setState({
				key: key,
				id_evaluacion: value.id_evaluacion,
				nombre: value.nombre,
				apellido_paterno: value.apellido_paterno,
				apellido_materno: value.apellido_materno,
				puesto: value.puesto,
				telefono: value.telefono,
				correo_electronico: value.correo_electronico,
				fecha_disponible: moment(value.fecha_disponible),
				hora_disponible: moment(value.fecha_disponible + ' ' + value.hora_disponible),
				
				confirmo: value.confirmo || null,
				aplicada: value.aplicada || null,
				score: value.score || null,
				id_cat_evaluacion_estatus: value.id_cat_evaluacion_estatus || null,
				evaluacion_estatus: evaluacion_estatus,
				mensaje: value.mensaje || null,
				fecha_entrevista: moment(value.fecha_entrevista),
				hora_entrevista: moment(value.fecha_entrevista + ' ' + value.hora_entrevista),
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			id_evaluacion: '',
			nombre: '',
			apellido_paterno: '',
			apellido_materno: '',
			puesto: '',
			telefono: '',
			correo_electronico: '',
			fecha_disponible: null,
			hora_disponible: null,
		});
	};
	
	
	add_candidato = () => {
		try {
			let candidatos = FieldsJs.Copy(this.state.lista_candidatos);
			
			if (!this.is_edited_candidato() && Number(this.props.item.total_candidatos) - this.state.lista_candidatos.length <= 0) {
				throw Object({
					status: false, mensaje: 'Se ha completado la cantidad de cantidatos para evaluar.'
				})
			}
			
			if (!this.state.nombre) {
				throw Object({
					status: false, mensaje: 'El campo nombre es requerido.'
				})
			}
			if (!this.state.apellido_paterno) {
				throw Object({
					status: false, mensaje: 'El campo apellido paterno es requerido.'
				})
			}
			
			if (!this.state.puesto) {
				throw Object({
					status: false, mensaje: 'El campo posición es requerido.'
				})
			}
			
			if (!this.state.telefono) {
				throw Object({
					status: false, mensaje: 'El campo teléfono es requerido.'
				})
			}
			
			if (!this.state.correo_electronico) {
				throw Object({
					status: false, mensaje: 'El campo correo electrónico es requerido.'
				})
			}
			
			if (!this.state.fecha_disponible) {
				throw Object({
					status: false, mensaje: 'El campo fecha disponible es requerido.'
				})
			}
			
			if (!this.state.hora_disponible) {
				throw Object({
					status: false, mensaje: 'El campo hora disponible es requerido.'
				})
			}
			
			if (this.is_edited_candidato()) {
				for (let i = 0; i < candidatos.length; i++) {
					let item = candidatos[i];
					
					if (this.state.key === i) {
						let evaluacion_estatus = '';
						for (let j = 0; j < this.state.cat_evaluacion_estatus.length; j++) {
							if (Number(item.id_cat_evaluacion_estatus) === Number(this.state.cat_evaluacion_estatus[j].id_cat_evaluacion_estatus)) {
								evaluacion_estatus = this.state.cat_evaluacion_estatus[j].evaluacion_estatus;
							}
						}
						item.id_cotizacion_candidato = this.state.id_cotizacion_candidato || null;
						item.nombre = this.state.nombre || null;
						item.apellido_paterno = this.state.apellido_paterno || null;
						item.apellido_materno = this.state.apellido_materno || "";
						item.puesto = this.state.puesto || null;
						item.telefono = this.state.telefono || null;
						item.correo_electronico = this.state.correo_electronico || null;
						item.fecha_disponible = this.state.fecha_disponible != null ? moment(this.state.fecha_disponible).format("YYYY-MM-DD") : null;
						item.hora_disponible = this.state.hora_disponible != null ? moment(this.state.hora_disponible).format("HH:mm") : null;
						
						item.confirmo = this.state.confirmo || null;
						item.aplicada = this.state.aplicada || null;
						//item.score = this.state.score || null;
						item.id_cat_evaluacion_estatus = this.state.id_cat_evaluacion_estatus || null;
						item.evaluacion_estatus = evaluacion_estatus;
						item.mensaje = this.state.mensaje || null;
						item.fecha_entrevista = this.state.fecha_entrevista != null ? moment(this.state.fecha_entrevista).format("YYYY-MM-DD") : null;
						item.hora_entrevista = this.state.hora_entrevista != null ? moment(this.state.hora_entrevista).format("HH:mm") : null;
					}
				}
			} else {
				let item;
				let evaluacion_estatus = '';
				for (let j = 0; j < this.state.cat_evaluacion_estatus.length; j++) {
					if (Number(this.state.id_cat_evaluacion_estatus) === Number(this.state.cat_evaluacion_estatus[j].id_cat_evaluacion_estatus)) {
						evaluacion_estatus = this.state.cat_evaluacion_estatus[j].evaluacion_estatus;
					}
				}
				item = {
					id_cotizacion_candidato: this.state.id_cotizacion_candidato || null,
					nombre: this.state.nombre || null,
					apellido_paterno: this.state.apellido_paterno || null,
					apellido_materno: this.state.apellido_materno || "",
					puesto: this.state.puesto || null,
					telefono: this.state.telefono || null,
					correo_electronico: this.state.correo_electronico || null,
					fecha_disponible: this.state.fecha_disponible != null ? moment(this.state.fecha_disponible).format("YYYY-MM-DD") : null,
					hora_disponible: this.state.hora_disponible != null ? moment(this.state.hora_disponible).format("HH:mm") : null,
					
					confirmo: this.state.confirmo || null,
					aplicada: this.state.aplicada || null,
					//score: this.state.score || null,
					id_cat_evaluacion_estatus: this.state.id_cat_evaluacion_estatus || null,
					evaluacion_estatus: evaluacion_estatus,
					mensaje: this.state.mensaje || null,
					fecha_entrevista: this.state.fecha_entrevista != null ? moment(this.state.fecha_entrevista).format("YYYY-MM-DD") : null,
					hora_entrevista: this.state.hora_entrevista != null ? moment(this.state.hora_entrevista).format("HH:mm") : null,
				};
				candidatos.push(item);
			}
			
			this.setState({
				key: null,
				lista_candidatos: candidatos,
				id_cotizacion_candidato: '',
				nombre: '',
				apellido_paterno: '',
				apellido_materno: '',
				puesto: '',
				telefono: '',
				correo_electronico: '',
				fecha_disponible: null,
				hora_disponible: null,
				confirmo: '',
				aplicada: '',
				score: '',
				id_cat_evaluacion_estatus: '',
				mensaje: '',
				fecha_entrevista: null,
				hora_entrevista: null,
			});
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar al candidato?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_candidatos = FieldsJs.Copy(this.state.lista_candidatos);
					
					lista_candidatos.splice(key, 1);
					
					this.setState({
						lista_candidatos: lista_candidatos
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	handleChangeCheckPST = (item, valor) => {
		
		let lista = this.state.lista_candidatos;
		for (let i = 0; i < lista.length; i++) {
			lista[i].check = this.state.checkPS ? false : true;
		}
		
		this.setState({
			checkPS: this.state.checkPS ? false : true
		});
		
		this.setState({
			lista_candidatos: lista
		});
		
		console.log(this.state.lista_candidatos);
	};
	
	handleChangeCheckPS = (item, valor) => {
		let lista = FieldsJs.Copy(this.state.lista_candidatos);
		let bandTodo = false;
		let cont = 0;
		lista.map((value) => {
			if (Number(item.id_cotizacion_candidato) === Number(value.id_cotizacion_candidato)) {
				value.check = !value.check;
			}
		});
		
		lista.map((value) => {
			if (value.check) {
				cont++;
			}
		});
		
		console.log('CONT', cont);
		if (cont === lista.length) {
			this.setState({
				checkPS: true
			})
		} else if (cont < lista.length) {
			this.setState({
				checkPS: false
			})
		}
		
		this.setState({
			lista_candidatos: lista
		});
		console.log("ITEM", item);
	};
	
	add_info_candidato = () => {
		
		let lista_partidas = FieldsJs.Copy(this.state.lista_candidatos || []);
		
		try {
			
			let bandC = false;
			for (let i = 0; i < lista_partidas.length; i++) {
				if (lista_partidas[i].check) {
					bandC = true;
				}
			}
			
			if (!bandC) {
				throw Object({
					status: false, mensaje: 'Selecciona por lo menos un candidato.'
				})
			}
			
		} catch (e) {
			console.error(e);
			this.props.showSnackBars('error', e.mensaje);
		}
		let evaluacion_estatus = '';
		for (let j = 0; j < this.state.cat_evaluacion_estatus.length; j++) {
			if (Number(this.state.id_cat_evaluacion_estatus) === Number(this.state.cat_evaluacion_estatus[j].id_cat_evaluacion_estatus)) {
				evaluacion_estatus = this.state.cat_evaluacion_estatus[j].evaluacion_estatus;
			}
		}
		
		lista_partidas.map((item) => {
			if (item.check) {
				item.confirmo = this.state.confirmo || null;
				item.aplicada = this.state.aplicada || null;
				item.score = this.state.score || null;
				item.id_cat_evaluacion_estatus = this.state.id_cat_evaluacion_estatus || null;
				item.id_cat_evaluacion_estatus = this.state.id_cat_evaluacion_estatus || null;
				item.evaluacion_estatus = evaluacion_estatus;
				item.mensaje = this.state.mensaje || null;
				item.fecha_entrevista = this.state.fecha_entrevista != null ? moment(this.state.fecha_entrevista).format("YYYY-MM-DD") : null;
				item.hora_entrevista = this.state.hora_entrevista != null ? moment(this.state.hora_entrevista).format("HH:mm") : null;
				item.check = false;
			}
		});
		
		this.setState({
			lista_candidatos: lista_partidas,
			checkPS: false
		});
		
		console.log("this.state.lista_candidatos", this.state.lista_candidatos);
		
	};
	
	nivelSelected = (nivel) => {
		console.log("NIVEL ::: ", nivel);
		
		if (FieldsJs.Field(nivel)) {
			let niveles = FieldsJs.Copy(this.state.cat_evaluacion_estatus);
			for (let i = 0; i < niveles.length; i++) {
				if (Number(nivel) === Number(niveles[i].id_cat_evaluacion_estatus)) {
					this.setState({
						mensaje: niveles[i].abreviatura
					});
				}
			}
		} else {
			this.setState({
				mensaje: ''
			});
		}
	};
	
	
	render() {
		
		let is_admin = this.is_admin();
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_orientacion}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					{/*<DialogTitle>
						Ingresa la información de los candidatos a evaluar
					</DialogTitle>*/}
					
					<DialogContent>
						
						<Grid container spacing={3}>
							
							{!this.is_admin() ? (
								<Grid item className={'text-left'} xs={12}>
									<Grid container spacing={1} className={'borde-punteado-lightgray-2'}>
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<FormControl component="fieldset" className={'w-100'}>
												<div className={'vertical-inline w-100'}>
													<div className={'v-center w-50 text-left'}>
														<FormLabel component="legend" className={'margin-10-B padding-5-T padding-5-L padding-20-R'}>Ingresa la información de los candidatos a evaluar</FormLabel>
													</div>
													<div className={'v-center w-50 text-right'}>
														<span style={{backgroundColor: Number(this.props.item.total_candidatos) - this.state.lista_candidatos.length > 0 ? '#165ba5' : '#a51113', color: 'white', borderRadius: '4px'}}
														      className={'margin-10-B padding-5 padding-10-L padding-10-R'}>{Number(this.props.item.total_candidatos) - this.state.lista_candidatos.length} {Number(this.props.item.total_candidatos) - this.state.lista_candidatos.length === 1 ? 'Candidato' : 'Candidatos'} por registrar</span>
													</div>
												</div>
											</FormControl>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<Person className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														margin="dense"
														name="nombre"
														label="Nombre"
														type="text"
														fullWidth
														value={this.state.nombre}
														onChange={this.handleChange}
														required
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<Person className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														margin="dense"
														name="apellido_paterno"
														label="Apellido Paterno"
														type="text"
														fullWidth
														value={this.state.apellido_paterno}
														onChange={this.handleChange}
														required
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<Person className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														margin="dense"
														name="apellido_materno"
														label="Apellido Materno"
														type="text"
														fullWidth
														value={this.state.apellido_materno}
														onChange={this.handleChange}
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<Work className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														margin="dense"
														type={'text'}
														fullWidth
														name="puesto"
														onChange={this.handleChange}
														label="Posición"
														value={this.state.puesto}
														required
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<PhoneIphoneOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														type={'text'}
														fullWidth
														name="telefono"
														onChange={this.handleChange}
														inputProps={{maxLength: 10}}
														onKeyPress={EnteroSolo}
														label="Teléfono/Whatsapp" autoComplete={'off'}
														value={this.state.telefono}
														required
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<AlternateEmailOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														type={'text'}
														fullWidth
														name="correo_electronico"
														onChange={this.handleChange}
														label="Correo electrónico" autoComplete={'off'}
														value={this.state.correo_electronico}
														required
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<Today className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<DatePicker
														format="yyyy/MM/dd"
														fullWidth
														clearable
														required
														inputProps={{readOnly: true}}
														KeyboardButtonProps={{
															'aria-label': 'Fecha',
														}}
														label="Fecha Disponible"
														value={this.state.fecha_disponible}
														onChange={(date) => {
															this.handleChange(null, null, null, date, 'fecha_disponible');
														}}
														minDate={moment(new Date()).add(1, 'days')}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														onError={console.log}
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<QueryBuilder className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TimePicker
														clearable
														fullWidth
														required
														format="hh:mm"
														label="Hora Disponible"
														value={this.state.hora_disponible}
														onChange={(date) => {
															this.handleChange(null, null, null, date, 'hora_disponible');
														}}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														onError={console.log}
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item className={'text-right padding-10-T padding-10-B'} xs={12}>
											<Tooltip
												TransitionComponent={Zoom}
												placement={"top"}
												title="Cancelar edición"
												children={(
													<span>
														<Fab size="small" color="primary"
														     aria-label="Cancelar edición"
														     onClick={() => this.edit_cancel()}
														     style={{marginLeft: "15px"}}
														     disabled={!this.is_edited_candidato()}
														>
															<CancelOutlined/>
														</Fab>
													</span>
												)}
											/>
											<Tooltip
												TransitionComponent={Zoom}
												placement={"top"}
												title={this.is_edited_candidato() ? "Actualizar" : "Agregar"}
												children={(
													<Fab size="small" color="primary"
													     aria-label={this.is_edited_candidato() ? "Actualizar" : "Agregar"}
													     onClick={() => this.add_candidato()}
													     style={{marginLeft: "15px"}}>
														{this.is_edited_candidato() ? (
															<SaveOutlined/>
														) : (
															<AddOutlined/>
														)}
													</Fab>
												)}
											/>
										</Grid>
									
									</Grid>
								
								</Grid>
							) : null}
							
							{this.is_admin() ? (
								<Grid item className={'text-left'} xs={12}>
									<Grid container spacing={1} className={'borde-punteado-lightgray-2'}>
										
										<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
											<FormControl component="fieldset">
												<FormLabel component="legend" className={'margin-10-B padding-5-T padding-5-L'}>Evaluación de los candidatos</FormLabel>
											</FormControl>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<Today className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<DatePicker
														format="yyyy/MM/dd"
														fullWidth
														clearable
														inputProps={{readOnly: true}}
														KeyboardButtonProps={{
															'aria-label': 'Fecha',
														}}
														label="Fecha Entrevista"
														value={this.state.fecha_entrevista}
														onChange={(date) => {
															this.handleChange(null, null, null, date, 'fecha_entrevista');
														}}
														minDate={moment(new Date()).add(1, 'days')}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														onError={console.log}
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<QueryBuilder className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TimePicker
														clearable
														fullWidth
														format="hh:mm"
														label="Hora Entrevista"
														value={this.state.hora_entrevista}
														onChange={(date) => {
															this.handleChange(null, null, null, date, 'hora_entrevista');
														}}
														clearLabel={'Limpiar'}
														okLabel={'Aceptar'}
														cancelLabel={'Cancelar'}
														onError={console.log}
													/>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item align={'left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<FiberManualRecord className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<FormControl fullWidth>
														<InputLabel htmlFor="age-native-activo">Confirmó</InputLabel>
														<Select
															native
															value={this.state.confirmo}
															onChange={this.handleChange}
															inputProps={{
																name: 'confirmo',
																id: 'age-native-activo',
															}}
														>
															<option value={''}>&nbsp;</option>
															<option value={1}>Si</option>
															<option value={0}>No</option>
														</Select>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item align={'left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<FiberManualRecord className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<FormControl fullWidth>
														<InputLabel htmlFor="age-native-activo">Aplicado</InputLabel>
														<Select
															native
															value={this.state.aplicada}
															onChange={this.handleChange}
															inputProps={{
																name: 'aplicada',
																id: 'age-native-activo',
															}}
														>
															<option value={''}>&nbsp;</option>
															<option value={1}>Si</option>
															<option value={0}>No</option>
														</Select>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>
										
										{/*<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<TrendingUp className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													<TextField
														type={'text'}
														fullWidth
														name="score"
														onChange={this.handleChange}
														label="Score" autoComplete={'off'}
														value={this.state.score}
													/>
												</Grid>
											</Grid>
										</Grid>*/}
										
										<Grid item className={'text-left'} xs={12} sm={6} md={6} lg={3} xl={3}>
											<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<HdrStrongOutlined className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>
													
													<FormControl fullWidth>
														<InputLabel>Nivel</InputLabel>
														<Select
															native
															value={this.state.id_cat_evaluacion_estatus}
															onChange={(e) => {
																this.handleChange(e);
																this.nivelSelected(e.target.value);
															}}
															name="id_cat_evaluacion_estatus"
															input={<Input/>}
														>
															<option value=""/>
															{this.state.cat_evaluacion_estatus.map(option => (
																<option key={option.id_cat_evaluacion_estatus}
																        value={option.id_cat_evaluacion_estatus}>
																	{option.evaluacion_estatus}
																</option>
															))}
														
														</Select>
													</FormControl>
												</Grid>
											</Grid>
										</Grid>
										
										<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
											{/*<Grid container spacing={1} alignItems={"flex-end"}>
												<Grid item className={'w-30-px'}>
													<Comment className={'w-100 text-gray'}/>
												</Grid>
												<Grid item className={'w-100-30-px'}>*/}
													<TextField
														type={'text'}
														fullWidth
														disabled
														multiline
														name="mensaje"
														variant="filled"
														onChange={this.handleChange}
														label="Dictamen"
														value={this.state.mensaje}
													/>
												{/*</Grid>
											</Grid>*/}
										</Grid>
										
										{!this.is_view() ? (
											<Fragment>
												<Grid item className={'text-right'} xs={12} sm={12} md={12} lg={3} xl={3}>
													<div className={'vertical-inline h-100'}>
														<div className={'v-center padding-15-R'}>
															<Fab
																style={{fontSize: '10px', padding: '10px 15px 10px 15px'}}
																variant="extended"
																size="small"
																color="primary"
																aria-label="Agregar"
																onClick={() => this.add_info_candidato()}
															>
																<CheckOutlined style={{fontSize: '12px', marginRight: '5px'}}/>
																agregar
															</Fab>
														</div>
														<div className={'v-center'}>
															<FormGroup>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			name='checkPS'
																			checked={this.state.checkPS}
																			onChange={(e) => {
																				this.handleChangeCheckPST(e.target.value);
																			}}
																			value={1}
																			color="primary"
																		
																		/>
																	}
																	label={'Todo'}
																/>
															</FormGroup>
														</div>
													</div>
												</Grid>
											</Fragment>
										) : null}
									
									</Grid>
								</Grid>
							) : null}
							
							<Grid item className={'text-left'} xs={12}>
								<Grid container spacing={1} className={'borde-punteado-lightgray-2'}>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<table className={'desing-mark'}>
											<thead>
											<tr>
												<th>Nombre</th>
												<th>Correo Electrónico</th>
												<th>Teléfono/WhatsApp</th>
												<th>Posición</th>
												<th>Fecha/Hora Disponible</th>
												{this.is_admin() ? (
													<Fragment>
														<th>Fecha/Hora Entrevista</th>
														<th>Confirmó</th>
														<th>Aplicado</th>
														{/*<th>Score</th>*/}
														<th>Nivel</th>
														<th>Dictamen</th>
													</Fragment>
												) : null}
												<th>Acciones</th>
											</tr>
											</thead>
											<tbody>
											{this.state.lista_candidatos.map((item, index) => (
												<tr key={index}>
													<td>
														{this.is_admin() ? (
															<FormGroup>
																<FormControlLabel
																	control={
																		<Checkbox
																			type="checkbox"
																			name="index"
																			checked={item.check}
																			onChange={(e) => {
																				this.handleChange(e);
																				this.handleChangeCheckPS(item, e.target.item);
																			}}
																			item={index}
																			color="primary"
																		/>
																	}
																	label={item.nombre + ' ' + item.apellido_paterno + ' ' + item.apellido_materno}
																/>
															</FormGroup>
														) : item.nombre + ' ' + item.apellido_paterno + ' ' + item.apellido_materno}
													</td>
													<td>{item.correo_electronico}</td>
													<td>{item.telefono}</td>
													<td>{item.puesto}</td>
													<td>{item.fecha_disponible + '/' + item.hora_disponible}</td>
													{this.is_admin() ? (
														<Fragment>
															<td>{!item.fecha_entrevista && !item.hora_entrevista ? 'Sin registrar' : item.fecha_entrevista + '/' + item.hora_entrevista}</td>
															<td>{item.confirmo === '' || item.confirmo === null ? 'Sin registrar' : Number(item.confirmo) === 1 ?
																<Fab size="small" color="primary" aria-label="add">Si</Fab> : <Fab size="small" color="secondary" aria-label="add">No</Fab>}
															</td>
															<td>{item.aplicada === '' || item.aplicada === null ? 'Sin registrar' : Number(item.aplicada) === 1 ?
																<Fab size="small" color="primary" aria-label="add">Si</Fab> : <Fab size="small" color="secondary" aria-label="add">No</Fab>}
															</td>
															{/*<td>{!item.score ? 'Sin registrar' : item.score}</td>*/}
															<td>{!item.evaluacion_estatus ? 'Sin registrar' : item.evaluacion_estatus}</td>
															<td>{!item.mensaje ? 'Sin registrar' : item.mensaje}</td>
														</Fragment>
													) : null}
													
													<td align={'left'}>
														<div className={'w-auto vertical-inline'}>
															<div className={'v-center'}>
																<Tooltip
																	TransitionComponent={Zoom}
																	placement={"top"}
																	title="Eliminar"
																	children={(
																		<Fab
																			size="small"
																			aria-label="Eliminar"
																			onClick={() => this.delete(item, index)}
																			style={{
																				marginLeft: "0px",
																				backgroundColor: "transparent",
																				color: "#808080",
																				boxShadow: "none",
																			}}
																			children={(
																				<DeleteOutlined/>
																			)}
																		/>
																	)}
																/>
															</div>
															<div className={'v-center'}>
																{this.state.key === index ? (
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"top"}
																		title="Producto en edición"
																		children={(
																			<Fab
																				size="small"
																				aria-label="Editar"
																				onClick={() => this.edit_candidato(item, index)}
																				style={{
																					marginLeft: "10px",
																					backgroundColor: "#f50057",
																					color: "white",
																				}}
																				children={(
																					<EditOutlined/>
																				)}
																			/>
																		)}
																	/>
																) : (
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"top"}
																		title="Editr producto"
																		children={(
																			<Fab
																				size="small"
																				aria-label="Editar"
																				onClick={() => this.edit_candidato(item, index)}
																				style={{
																					marginLeft: "10px",
																					backgroundColor: "transparent",
																					color: "#808080",
																					boxShadow: "none",
																				}}
																				children={(
																					<EditOutlined/>
																				)}
																			/>
																		)}
																	/>
																)}
															</div>
														</div>
													</td>
												</tr>
											))}
											{!(this.state.lista_candidatos.length > 0) ? (
												<tr>
													<td colSpan={(is_admin ? 12 : 6)}
													    align={'center'}>
														<VistaVacia
															numero={null}
															mensaje={'Ningún candidato agregado.'}
															image={carrito_de_compra_3}
															padding={'20px'}
															paddingText={'20px'}
															height={'80px'}
															width={'80px'}
														/>
													</td>
												</tr>
											) : null}
											</tbody>
										</table>
									</Grid>
								</Grid>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<DialogActions>
						
						<Fragment>
							<Button onClick={this.close} color="primary">
								{this.props.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
							</Button>
							{this.props.tipo !== 'view' ? (
								<Button onClick={this.save} color="primary">
									{'Guardar'}
								</Button>
							) : ''}
						</Fragment>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCandidatos.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCandidatos;
