import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Zoom from '@material-ui/core/Zoom';
import Add from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import {EmpresaService} from '../../../../services/_Sis/EmpresaService/EmpresaService';
import IconButton from "@material-ui/core/IconButton";
import {EditOutlined, HdrStrongOutlined, HdrWeakOutlined} from '@material-ui/icons';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

import {CatService} from "../../../../services/_Cat/CatService/CatService";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";

import IntegrationNotistack, {ShowSnackBarsNotistack} from "../../../../settings/IntegrationNotistack/IntegrationNotistack";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";


class ModalEmpresa extends Component {
	
	state = {};
	
	Usr = ReactLocalStorageService.get('Usr') || {};
	
	constructor(props) {
		
		super(props);
		
		this.state = {
			open: false,
			scroll: 'paper',
			list_cat_tipo_usuario: [],
			id_empresa: props.id_empresa || 0,
			tipo: props.tipo,
			isjefe: '',
			empresa: '',
			estado: '',
			id_cat_municipio: '',
			id_cat_municipio_empresa: '',
			rfc: '',
			id_cat_estado_empresa: '',
			correo_electronico: '',
			telefono: '',
			municipio: '',
			cat_estado: [],
			cat_municipio: [],
			cat_municipio_entidad: [],
		};
		
		this.handleChange = this.handleChange.bind(this);
		
		this.listTipoUsuario = this.listTipoUsuario.bind(this);
		
		this.save = this.save.bind(this);
		this.add = this.add.bind(this);
		this.edit = this.edit.bind(this);
		this.view = this.view.bind(this);
		
		this.BOTON_ACCION = this.BOTON_ACCION.bind(this);
		this.ListEstado();
	}
	
	showSnackBars(type, message) {
		ShowSnackBarsNotistack(this.props, type, message, null);
	};
	
	handleChange(e) {
		const {value, name, checked, type} = e.target;
		this.setState({
			[name]: type === 'checkbox' ? checked : value
		});
	}
	
	openModal = () => {
		this.setState({open: true, scroll: 'paper'});
	};
	
	modalClose = () => {
		this.setState({open: false});
	};
	
	listTipoUsuario = () => {
		CatService.ListTipoUsuario().then((response) => {
			this.setState({list_cat_tipo_usuario: response.data});
		})
	};
	
	ListEstado = () => {
		CatService.ListEstado().then((response) => {
			this.setState({
				cat_estado: response.data
			});
		});
	};
	
	ListMunicipio = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		
		for (let i = 0; i < this.state.cat_estado.length; i++) {
			if (Number(this.state.cat_estado[i].id_cat_estado) === Number(id_cat_estado)) {
				this.setState({
					estado: this.state.cat_estado[i].estado
				});
			}
		}
		
		CatService.ListMunicipio(id_cat_estado).then((response) => {
			this.setState({
				cat_municipio: response.data
			});
		});
	};
	
	
	ListMunicipioEntidad = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		
		for (let i = 0; i < this.state.cat_estado.length; i++) {
			if (Number(this.state.cat_estado[i].id_cat_estado) === Number(id_cat_estado)) {
				this.setState({
					estado: this.state.cat_estado[i].estado
				});
			}
		}
		
		CatService.ListMunicipio(id_cat_estado).then((response) => {
			this.setState({
				cat_municipio_entidad: response.data
			});
		});
	};
	
	save = () => {
		if (this.state.id_empresa > 0) {
			EmpresaService.Modificar(this.state).then((response) => {
				this.modalClose();
				this.props.$BroadcastModalUsuarios({
					accion: 'list',
					status: true
				});
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		} else {
			EmpresaService.Agregar(this.state).then((response) => {
				this.modalClose();
				this.props.$BroadcastModalUsuarios({
					accion: 'list',
					status: true
				});
				this.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		console.log(this.props);
		
		this.listTipoUsuario();
		
		this.setState({
			id_empresa: '',
			empresa: '',
			estado: '',
			id_cat_municipio: '',
			id_cat_municipio_empresa: '',
			rfc: '',
			id_cat_estado_empresa: '',
			correo_electronico: '',
			telefono: '',
			municipio: '',
			activo: true,
			sendmail: true,
		});
		
		this.openModal();
		
	};
	
	edit = () => {
		
		const {id_empresa, item} = this.props;
		
		console.log(this.props);
		
		this.listTipoUsuario();
		
		this.setState({
			id_empresa: item.id_empresa || '',
			empresa: item.empresa || '',
			estado: item.estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			id_cat_municipio_empresa: item.id_cat_municipio_empresa || '',
			rfc: item.rfc || '',
			id_cat_estado_empresa: item.id_cat_estado_empresa || '',
			correo_electronico: item.correo_electronico || '',
			telefono: item.telefono || '',
			municipio: item.municipio || '',
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	view = () => {
		
		const {id_empresa, item} = this.props;
		
		console.log(this.props);
		
		this.listTipoUsuario();
		
		this.setState({
			id_empresa: item.id_empresa || '',
			empresa: item.empresa || '',
			estado: item.estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			id_cat_municipio_empresa: item.id_cat_municipio_empresa || '',
			rfc: item.rfc || '',
			id_cat_estado_empresa: item.id_cat_estado_empresa || '',
			correo_electronico: item.correo_electronico || '',
			telefono: item.telefono || '',
			municipio: item.municipio || '',
			activo: (item.activo === 1),
		});
		
		this.openModal('paper');
		
	};
	
	BOTON_ACCION = () => {
		var BTN_ACTION = '';
		
		if (this.state.id_empresa > 0) {
			if (this.state.tipo === 'edit') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Editar">
						<IconButton aria-label="Editar" onClick={() => this.edit()}>
							<EditOutlined/>
						</IconButton>
					</Tooltip>
				);
			} else if (this.state.tipo === 'view') {
				BTN_ACTION = (
					<Tooltip TransitionComponent={Zoom} placement={"top"} title="Detalles">
						<IconButton aria-label="Detalles" onClick={() => this.view()}>
							<SearchOutlined/>
						</IconButton>
					</Tooltip>
				);
			}
		} else {
			BTN_ACTION = (
				<Zoom className={'btn-fixed-bottom-right cursor-pointer'} key={'inherit'} timeout={1500} in={true}
				      style={{transitionDelay: `${100}ms`}} unmountOnExit>
					<Fab color={'primary'} onClick={() => this.add()}>
						<Add/>
					</Fab>
				</Zoom>
			);
		}
		return BTN_ACTION;
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([2], this.Usr.id_cat_tipo_usuario);
	};
	
	render() {
		
		const classes = {};
		
		const BTN_ACTION = this.BOTON_ACCION();
		
		return (
			<div>
				
				{BTN_ACTION}
				
				<Dialog disableBackdropClick disableEscapeKeyDown maxWidth={'sm'} open={this.state.open}
				        fullWidth={true} scroll={this.state.scroll} onClose={this.modalClose}
				        aria-labelledby="scroll-dialog-title"
				        PaperComponent={DraggableModal}
				>
					
					<DialogTitle>{this.state.tipo === 'edit' ? 'Actualizar' : (this.state.tipo === 'view' ? 'Detalle' : 'Agregar')} Empresa</DialogTitle>
					
					<Divider/>
					
					<DialogContent className={'padding-20'}>
						
						<Grid container spacing={2}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									
									helperText="Requerido"
									name="empresa"
									label="Nombre de la empresa"
									defaultValue={this.state.empresa}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									
									helperText="Requerido"
									name="rfc"
									label="RFC"
									defaultValue={this.state.rfc}
								/>
							
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<FormControl fullWidth className={classes.textField}>
									<InputLabel>Estado</InputLabel>
									<Select
										native
										value={this.state.id_cat_estado_empresa}
										onChange={(e) => {
											this.handleChange(e);
											this.ListMunicipio(e);
										}}
										name="id_cat_estado_empresa"
										input={<Input/>}
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.input
											}
										}}
									>
										<option value=""/>
										{this.state.cat_estado.map(option => (
											<option key={option.id_cat_estado}
											        value={option.id_cat_estado}>
												{option.estado}
											</option>
										))}
									
									</Select>
									<FormHelperText>Requerido</FormHelperText>
								</FormControl>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
								<FormControl fullWidth className={classes.textField}>
									<InputLabel>Municipio</InputLabel>
									<Select
										native
										value={this.state.id_cat_municipio_empresa}
										onChange={this.handleChange}
										name="id_cat_municipio_empresa"
										input={<Input/>}
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.input
											}
										}}
									>
										<option value=""/>
										{this.state.cat_municipio.map(option => (
											<option key={option.id_cat_municipio}
											        value={option.id_cat_municipio}>
												{option.municipio}
											</option>
										))}
									
									</Select>
									<FormHelperText>Requerido</FormHelperText>
								</FormControl>
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								
								<TextField
									className={'margin-0'}
									fullWidth
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									type="text"
									margin="dense"
									helperText="Requerido"
									name="correo_electronico"
									label="Correo electrónico"
									defaultValue={this.state.correo_electronico}
								/>
							
							</Grid>
							
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									type="text"
									fullWidth
									margin="dense"
									name="telefono"
									helperText="Requerido"
									onChange={this.handleChange}
									inputProps={{maxLength: 10}}
									onKeyPress={EnteroSolo}
									label="Teléfono" autoComplete={'off'}
									value={this.state.telefono}
									className={'margin-0'}
								/>
							</Grid>
							
							
							{/*<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									className={'margin-0'}
									select
									fullWidth
									margin="dense"
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
									SelectProps={{
										native: true,
										MenuProps: {
											className: classes.menu,
										},
									}}
									
									helperText="Requerido"
									name="id_cat_tipo_usuario"
									label="Tipo de usuario"
									value={this.state.id_cat_tipo_usuario}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.state.list_cat_tipo_usuario.map(option => (
										<option key={option.id_cat_tipo_usuario} value={option.id_cat_tipo_usuario}>
											{option.tipo_usuario}
										</option>
									))}
								</TextField>
							
							</Grid>*/}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox type="checkbox" name='activo' checked={this.state.activo}
											          onChange={this.handleChange} value="activo" color="primary"
											          disabled={this.state.tipo === 'view'}/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<Button onClick={() => this.modalClose()} color="primary">
							{this.state.tipo === 'view' ? 'Cerrar' : 'Cancelar'}
						</Button>
						
						{this.state.tipo !== 'view' ? (
							<Button onClick={() => this.save()} color="primary">
								{this.state.id_empresa > 0 ? 'Actualizar' : 'Agregar'}
							</Button>
						) : ''}
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalEmpresa.propTypes = {
	tipo: PropTypes.string.isRequired,
	$BroadcastModalUsuarios: PropTypes.func.isRequired,
};

export default IntegrationNotistack(ModalEmpresa);
