import React, {Component, Fragment} from 'react';

import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Slide from "@material-ui/core/Slide/index";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {FieldsJs, hideSpinner, showSpinner} from "../../../../settings/General/General";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Divider from "@material-ui/core/Divider";
import {DropzoneArea} from "material-ui-dropzone";
import {DropboxApi} from "../../../../settings/Dropbox/DropboxApi";
import {CotizacionService} from "../../../../services/_Sis/CotizacionService/CotizacionService";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Tooltip from "@material-ui/core/Tooltip";
import image from '../../../../assets/img/acresco_talento.svg'
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import {CloudDownload, CloudUpload, Description, PhotoCameraOutlined} from "@material-ui/icons";
import DivSvg from "../../../Include/MiniComponents/DivSvg";
import Zoom from "@material-ui/core/Zoom";



class ModalNube extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			tipo: props.tipo || '',
			empresa: (props.item || '').empresa,
			correo_electronico: (props.item || '').correo_electronico,
			id_cotizacion: (props.item || '').id_cotizacion,
			cotizaciones: (props.item || []).cotizaciones,
			id_cotizador: '',
			files: [],
			archivo: [],
			dropzone_area_open: true,
			clearfiles: false
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	openModal = () => {
		const {item} = this.props;
		this.setState({
			modal: {
				open: true
			},
			empresa: item.empresa || '',
			correo_electronico: item.correo_electronico || '',
			id_cotizacion: item.id_cotizacion || '',
			cotizaciones: item.cotizaciones || [],
		});
		console.log(this.state, item);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	init = () => {
		return new Promise((resolve, reject) => {
			resolve(true);
		});
	};

	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	listaCotizaciones = () => {
		try {
			
			CotizacionService.ListarArchivo(this.state).then((response) => {
				this.setState({
					cotizaciones: response.data
				});
			}).catch((error) => {
				this.setState({
					cotizaciones: []
				});
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	save = () => {
		try {
			
			if (this.state.files.length === 0) {
				throw Object({
					status: 400,
					mensaje: "Seleciona por lo menos un archivo."
				});
			}
			
			showSpinner('spinner');
			this.dropUpload(0);
			
			console.log("THIS.STATE::: ", this.state);
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	dropUpload = (index) => {
		let cont_files = this.state.files.length - 1;
		this.getBase64(this.state.files[index], true).then(response => {
			let archivos = FieldsJs.Copy(this.state.archivo);
			if (response) {
				archivos.push(response);
			}
			this.setState({
				archivo: archivos
			});
			
			if (index < cont_files) {
				index = index + 1;
				this.dropUpload(index);
			} else {
				hideSpinner('spinner', 600);
				console.log("ARCHIVOS ::: ", this.state.archivo);
				CotizacionService.AgregarArchivo(this.state).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.clearForm();
					this.listaCotizaciones();
					//this.closeModal();
					console.log('SUCCESS::: ', response);
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	getBase64 = (e, dropzone) => {
		return new Promise((resolve, reject) => {
			let files;
			if (dropzone) {
				files = e;
			} else {
				console.log(e.target);
				files = e.target.files;
			}
			
			DropboxApi.Upload('/Cotizaciones/' + this.state.correo_electronico + '/' + files.name, files).then(function (response) {
				
				resolve(response.path_display);
				
			}).catch(function (error) {
				reject(error);
			});
		});
	};
	
	clearForm = () => {
		this.setState({
			clearfiles: false,
			dropzone_area_open: false,
			files: [],
			archivo: [],
		});
		this.setState({
			clearfiles: true,
			dropzone_area_open: true
		});
	};
	
	handleChangeDropzoneArea = (files) => {
		console.log("FILES: ", files);
		
		this.setState({
			files: files
		}, () => {
			console.log("CONTENT:::: ", files);
		});
	};
	
	
	exportar = (path) => {
		//path = CONFIG.src + path;
		DropboxApi.Download(path).then(function (data) {
			console.log('Descarga con éxito!');
		}).catch(function (error) {
			console.error(error);
		});
		//window.open(path, '', 'width=900, height=600');
	};
	
	render() {
		const is_root = this.is_root();
		let {item} = this.props;
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span onClick={() => this.open()} style={{cursor: 'pointer'}}>
							{this.props.componente}
						</span>
					</Fragment>
				) : null}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title" className={'cotizador'}
				>
					
					<DialogTitle>
						Nueva Cotización
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={2} className={'cotizador_fondo'}>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<p className={'margin-0'}>
									Ingresa el documento de la cotización de la empresa {this.state.empresa}.
								</p>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<div className={'w-100'}>
									{
										this.state.dropzone_area_open ? (
											<div className={''}>
												<DropzoneArea
													onChange={this.handleChangeDropzoneArea.bind(this)}
													open={this.state.open}
													filesLimit={10}
													dropzoneText={'Arrastre y suelte el expediente del trabajador aquí o haga clic'}
													acceptedFiles={[]}
													showPreviews={false}
													maxFileSize={5000000}
													showAlerts={false}
													showFileNames={true}
													showFileNamesInPreview={true}
													clearOnUnmount={this.state.clearfiles}
												/>
											</div>
										) : null
									}
								</div>
							</Grid>
							
							{this.state.cotizaciones.length > 0 ? (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Table className={'desing-acresco-cotizaciones'}>
									<TableHead>
										<TableRow>
											<TableCell component="th" align={'left'} style={{width: "50%"}}>Documento(s)</TableCell>
											<TableCell component="th" align={'left'}>Fecha Alta</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.cotizaciones.map((item, key) => (
											<Fragment key={key}>
												<TableRow>
													<TableCell style={{width: "50%"}} align={'center'} className={'padding-5-L padding-5-R'}>
														<div className={'w-100'}>
																<Tooltip TransitionComponent={Zoom} placement={"top"} title="Descargar Documento">
																	<IconButton aria-label="Descargar Documento" onClick={() => this.exportar(item.archivo)}>
																		<Description style={{color: '#8E1E32'}}/>
																	</IconButton>
																</Tooltip>
														</div>
													</TableCell>
													<TableCell align={'left'}>{item.created_at}</TableCell>
												</TableRow>
											</Fragment>
										
										))}
									</TableBody>
								</Table>
							</Grid>) : null}
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						<Grid container spacing={2}>
							<Grid item xs={6} align={'left'}>
								<Button onClick={() => this.close()} color="primary">
									Cerrar
								</Button>
							</Grid>
							<Grid item xs={6} align={'right'}>
								<Button onClick={() => this.save()} color="primary">
									Guardar
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalNube.propTypes = {
	item: PropTypes.object.isRequired,
	componente: PropTypes.element.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalNube;
